@import 'styles/functions';
@import 'styles/colors';

.view-name-modal {
  .ant-modal-content {
    .ant-modal-header {
      padding: rem(24) rem(24) 0;
      border-radius: rem(12);
      border-bottom: none;
    }

    .ant-modal-body {
      .view-name-body {
        gap: rem(16);

        .view-name-form {
          gap: rem(24);
        }
      }
    }
  }
}
