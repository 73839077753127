@import 'styles/colors';
@import 'styles/functions';

.purchase-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  min-height: rem(80);

  .connection-dropdown {
    width: rem(250);
  }
}
