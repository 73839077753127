@import 'styles/functions';
@import 'styles/colors';

.share-report-drawer {
  .search-placeholder {
    color: $color-grey-2;
  }

  .share-list {
    .selected {
      .arrow-down {
        color: $color-regent-grey;
      }

      .count {
        .count-value {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }
    }
  }
}
