@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.data-center-header {
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  height: rem($page-header-height);

  .header-sub-title {
    color: $color-grey-5;
  }
}
