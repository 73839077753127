@import 'styles/functions';
@import 'styles/colors';

.credentials-login-page {
  .login-header-section {
    height: rem(82);
    background-color: $color-pure-black;

    .app-logo {
      width: rem(220);
      margin: rem(24) rem(40);
    }

    .deloitte-logo {
      margin: rem(24) rem(40);
    }
  }

  .body-section {
    background-color: $color-white;
  }

  .section-container {
    border: 1px solid $color-grey-13;
    width: rem(383);
    border-radius: rem(16);

    .content-section {
      padding: rem(24);
      gap: rem(16);

      .title-section {
        .sub-heading {
          color: $color-grey-9;
        }
      }

      .otp-input-field {
        width: rem(38);
        height: rem(38);
        padding: 0;
        text-align: center;
        caret-color: transparent;
      }

      .qr-code {
        .query-message {
          color: $color-grey-9;
        }

        .send-again-cta {
          padding: 0;
          height: auto;
        }
      }
    }
  }
}
