@import 'styles/colors';

.cmdb-tags-dropdown {
  &.ant-select.design-v2
    .ant-select-selector
    .ant-select-selection-overflow
    .ant-select-selection-overflow-item
    .ant-select-selection-item
    .ant-select-selection-item-content {
    color: $color-grey-2;
  }
}
