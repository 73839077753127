@import 'styles/functions';
@import 'styles/colors';

.dashboard-content {
  .upper-dashboard {
    z-index: 2;
    top: 0;
    position: sticky;
  }

  .dashboard-navigation {
    top: rem(80);
    position: sticky;
    z-index: 2;
  }

  .dashboard-view {
    margin: rem(24);

    &.margin-less-content {
      margin: 0;

      .inner-dashboard-content {
        .tag-filters {
          margin-bottom: 0;
          padding-bottom: 0;
        }
        margin: rem(24);
      }
    }

    .graph-heading {
      .previous-icon {
        color: var(--button-icon-color);
        height: rem(16);
        width: rem(16);
      }

      .sub-title {
        color: $color-grey-5;
      }
    }

    .dual-axis-charts,
    .stacked-column-charts,
    .horizontal-bar-charts,
    .pie-donut-charts {
      &.graph-card {
        height: auto;
        min-height: 100%;

        .graph {
          height: rem(280);
          min-height: rem(280);
        }
      }
    }
  }
}
