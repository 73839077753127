@import 'styles/colors';
@import 'styles/functions';

.container-cost-dashboard-header {
  background-color: $color-white;
  &-right {
    gap: rem(24);
  }
  .table-view-title {
    margin-right: rem(12);
  }

  .connection-dropdown {
    width: rem(250);
  }
}
