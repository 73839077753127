@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';
@import 'styles/variables';

.import-file-form-container {
  .import-file-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
    .back-arrow {
      color: var(--button-icon-color);
    }
  }

  .form-section {
    padding-top: 1rem;

    .collapse-container {
      width: 50%;
      gap: rem(32);
      border: none;
      background-color: transparent;

      .panel {
        .collapse-body {
          .validation-loader {
            position: absolute;
            top: 0;
            left: 0;
            background-color: $color-grey-0;
            opacity: 0.5;
            z-index: 1;
          }

          .schema-definition {
            padding: 1rem 0 1rem 1rem;
            border: 0.5px solid $color-grey-1;
            border-radius: rem(12);

            .schema-list {
              padding-right: rem(8);
              margin-right: rem(8);
              max-height: rem(400);
              overflow-y: auto;
            }

            .add-field-button {
              padding: rem(7) rem(12);
              &.ant-btn {
                &.ant-btn-primary {
                  @extend .font-button-small;
                }
              }
            }

            .ant-select-selector {
              height: rem(38);
            }
          }
        }
      }
    }
  }
}
