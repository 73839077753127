@import 'styles/functions';
@import 'styles/colors';

.report-chart-options {
  margin: rem(8) 0;

  .options-divider {
    border-color: $color-scroll-bar;
  }
}
