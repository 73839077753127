@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.roles-page {
  .roles-page-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .roles-page-content {
    padding: rem(24);

    .table-section {
      margin-top: rem(16);
    }
  }
}
