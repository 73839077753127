@import 'styles/functions';
@import 'styles/colors';

.instance-table {
  .status {
    border-radius: rem(22);
    padding: rem(4) rem(12);
    width: fit-content;
    text-transform: capitalize;
    color: $color-success-message;
    background: $status-active-gradient;
  }

  .ant-table-thead {
    .ant-table-column-has-sorters {
      .ant-table-column-sorters {
        align-items: unset;
      }
    }
  }
  .ant-table-wrapper {
    .ant-table {
      &-container {
        .ant-table-header {
          .ant-table-thead {
            .ant-table-cell.ant-table-cell-fix-left {
              z-index: 2 !important;
            }
          }
        }
        .ant-table-content {
          height: calc(100vh - 25em);
        }
        .ant-table-body {
          height: calc(100vh - 24em);
        }
      }
    }

    & .ant-table-tbody > tr.ant-table-row-selected > td {
      background: $color-white;
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-body
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }
    &.design-v2
      .ant-table.ant-table-empty
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: none;
        }
      }
    }
  }
}
