@import 'styles/functions';
@import 'styles/colors';

.provider-footer {
  position: sticky;
  bottom: 0;
  right: 0;
  border-top: rem(1) solid $color-grey-13;
  padding: rem(8) rem(16);
  background-color: $color-white;

  .vertical-rule {
    border: 1px solid #d6e0ea;
    margin: rem(4);
  }
}
