@import 'styles/functions';
@import 'styles/colors';

.reports {
  .reports-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }
}
