@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.dashboard-content {
  .cost-cards {
    overflow-x: auto;
    overflow-y: hidden;
    border: 1px solid $color-grey-13;
    border-radius: rem(12);
  }

  #graph-container {
    border: 0.5px solid $color-grey-13;
    border-radius: rem(12);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    transition: 0.3s;
    &.selected {
      border: rem(2) solid var(--primary-color);
      flex: 1.5;
    }
  }

  .map-card {
    &.graph-card {
      height: rem(444) !important;

      .graph {
        height: rem(336) !important;
      }
    }
  }

  .graph-card {
    height: rem(336);
    min-height: rem(336);
    padding: rem(16);
    overflow: hidden;

    &.service-spend-profile {
      height: auto;
      .graph {
        height: auto !important;
      }
    }

    .filters {
      display: flex;
      gap: rem(16);
      margin-top: rem(8);

      .ant-select {
        width: 30%;
      }
      .unit-cost-time-period {
        width: rem(224);
      }
    }

    .graph {
      height: rem(240);
      margin-top: rem(16);

      .graph-area {
        height: inherit;
        &.with-table {
          width: 70%;
        }
      }

      &.no-filters {
        height: rem(280);
      }

      .color-block {
        width: rem(12);
        height: rem(12);
        border-radius: rem(4);
      }

      .tooltip-deep-dive-label {
        color: $color-grey-9;
      }
    }

    .horizontal-rule {
      border: 1px solid $color-grey-13;
    }

    .legend-color {
      width: rem(16);
      height: rem(16);
      border-radius: rem(4);
    }

    .legend-text {
      white-space: nowrap;
      color: $color-grey-5;
    }

    &.aws-cost-and-credit-breakdown {
      height: rem(390);

      .graph {
        height: rem(290);
      }
    }
  }
}

.expand-modal {
  .color-block {
    width: rem(12);
    height: rem(12);
    border-radius: rem(4);
  }

  .graph-card {
    .horizontal-rule {
      border: 1px solid $color-grey-13;
    }

    .legend-color {
      width: rem(16);
      height: rem(16);
      border-radius: rem(4);
    }

    .legend-text {
      white-space: nowrap;
      color: $color-grey-5;
    }
  }
}

.dashboard-message {
  margin-top: rem($page-header-height);
}
