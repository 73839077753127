@import 'styles/colors';
@import 'styles/functions';

$font-family-open-sans: 'Open Sans';

@mixin font(
  $fontSize: '',
  $fontWeight: normal,
  $color: '',
  $line-height: '',
  $letter-spacing: '',
  $textAlign: unset,
  $fontStyle: normal,
  $fontFamily: $font-family-open-sans
) {
  @if $fontSize {
    font-size: $fontSize;
  }

  @if $fontWeight {
    font-weight: $fontWeight;
  }

  @if $color {
    color: $color;
  }
  /* Unitless values: use this number multiplied
  by the element's font size */
  @if $line-height {
    line-height: $line-height;
  }

  @if $letter-spacing {
    letter-spacing: $letter-spacing;
  }

  @if $textAlign {
    text-align: $textAlign;
  }

  @if $fontStyle {
    font-style: $fontStyle;
  }

  @if $fontFamily {
    font-family: $fontFamily;
  }
}

@mixin status-text {
  .status-purple {
    color: $color-highlighter-purple;
  }
  .status-orange {
    color: $color-fn-orange;
  }
  .status-cyan {
    color: $color-cyan;
  }
  .status-green {
    color: $color-secondary-button;
  }
  .status-grey {
    color: $color-grey-5;
  }
  .status-violet {
    color: $color-violet;
  }
  .status-light-green {
    color: $color-primary-green;
  }
  .status-red {
    color: $color-fn-red;
  }
  .status-chip {
    padding: rem(3) rem(7);
    border-radius: rem(12);
    &-red {
      color: $color-fn-red;
      background: $color-error-bg;
    }
    &-green {
      color: $color-success-message;
      background: $color-success-bg;
    }
    &-yellow {
      color: $color-dark-yellow;
      background: $color-light-yellow;
    }
    &-grey {
      color: $color-grey-9;
      background: $grey-shade-linear-gradient;
    }
  }
}

@mixin ant-table-heading {
  .ant-table-thead {
    > tr > th,
    > tr > td {
      background: $color-grey-12;
      border-bottom: 1px solid $color-grey-13;
      @extend .font-caption-bold;
      color: $color-grey-9;
      border-radius: 0;

      &.ant-table-cell-fix-left,
      &.ant-table-cell-fix-right {
        z-index: 1;
      }
    }
  }
}

@mixin ant-table-body {
  @extend .new-styled-scroll;

  tbody,
  tfoot {
    > tr {
      &:last-child > td {
        border-bottom: none;
      }

      > td {
        border-inline-end: transparent;
        border-color: $color-grey-13;
        overflow-wrap: anywhere;
        @extend .font-caption;

        &.ant-table-cell-fix-left {
          z-index: 1;
        }

        &.ant-table-row-expand-icon-cell {
          color: $color-regent-grey;
        }
      }

      &.expanded-row-item > td {
        border-bottom: none;
      }
    }
  }

  tfoot > tr {
    &:first-child > td {
      border-top: 1px solid $color-grey-13;
    }

    > td {
      background-color: $color-grey-12;
      @extend .font-caption-bold;
    }
  }

  .ant-table-expanded-row {
    td.ant-table-cell {
      background-color: $color-white;

      .ant-table-wrapper {
        padding-bottom: rem(8);
        .ant-table {
          margin-inline: 0;

          .ant-table-container {
            border-top: none;
          }
        }
      }
    }
  }
}

@mixin service-spend-profile-color {
  &.increasing-spend {
    color: $color-fn-red;
  }

  &.decreasing-spend {
    color: $color-bright-green;
  }

  &.inconsistent-spend {
    color: $color-secondary-purple;
  }

  &.constant-spend {
    color: $color-gold-foil;
  }
}
