@import 'styles/functions';
@import 'styles/colors';

.auto-complete-filter-fields {
  .condition-autocomplete {
    margin-left: 1rem;
    border: 1px transparent solid;
    transition: all 0.3s ease-in-out;

    &:hover,
    &:focus,
    &.focused {
      transition: all 0.3s ease-in-out;
      border-radius: rem(4);
    }
  }

  .conjunction {
    font-style: italic;
  }
}
