@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';
@import 'styles/variables';

.logo-container {
  height: rem(80);
  z-index: 4;
  border-bottom: $color-grey-10 1px solid;

  .element {
    .short-app-logo {
      width: rem(50);
    }
  }

  .version {
    padding: rem(2) rem(6);
    @extend .font-subHeader-small;
    position: absolute;
    top: rem(69);
    left: rem(16);
    border: $color-grey-10 1px solid;
    color: $color-grey-9;
    border-radius: rem(10);
    background-color: $color-off-white;
  }
}
