@import 'styles/colors';
@import 'styles/functions';

.cost-center-drawer {
  .ant-drawer-body {
    overflow: hidden;

    .cost-center-container {
      .pending-message {
        color: $color-grey-9;
      }

      .cost-center {
        border: 0.5px solid $color-grey-1;
        border-radius: rem(12);
        padding: rem(8);

        .cost-center-list {
          max-height: calc(100vh - 28em);
          overflow-y: auto;
          overflow-x: hidden;
          flex-flow: wrap;
          padding-right: rem(8);

          .cost-center-item {
            background-color: $color-grey-12;
            padding: rem(8) 0;
            border-radius: rem(8);
            margin-left: 0 !important;
            margin-right: 0 !important;

            .csc-input {
              background-color: $color-white;
            }

            .delete-cost-center-cta {
              color: $color-regent-grey;
            }
          }

          .duplicate-error {
            padding-left: rem(8);
          }
        }
      }
    }
  }
}
