@import 'styles/colors';
@import 'styles/variables';
@import 'styles/functions';

.user-page {
  .user-page-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .user-page-content {
    padding: rem(24);

    .table-section {
      margin-top: rem(16);

      .active {
        color: $color-primary-button;
      }

      .deactivated {
        color: $color-grey-4;
      }

      .pending {
        color: $color-fn-orange;
      }
    }
  }
}
