@import 'styles/functions';
@import 'styles/colors';

.yaml-modal {
  .ant-modal-content {
    height: calc(100vh - 15em);
    max-height: calc(100vh - 15em);
    overflow-y: auto;

    .yaml-modal-header {
      border-bottom: 1px solid $color-grey-13;
      height: rem(30);
    }
  }
  .copy-icon {
    position: relative;
  }
  .copy-text {
    position: absolute;
    right: 0;
    top: 7em;
  }
}
