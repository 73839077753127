@import 'styles/mixins';
@import 'styles/colors';
@import 'styles/functions';

.spend-profile-icon {
  @include service-spend-profile-color;

  .constant-spend-icon {
    width: rem(21);
    height: rem(6);
    background-color: $color-gold-foil;

    &.small {
      width: rem(12);
      height: rem(2);
    }
  }
}
