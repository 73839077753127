@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.anomaly-detection-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  height: rem($page-header-height);

  .connection-list {
    width: rem(242);
  }
}
