@import 'styles/functions';
@import 'styles/colors';

.gprofiler-ticket-info {
  padding-top: rem(16);
  color: $color-secondary-black;

  .status {
    border-radius: rem(16);
    padding: rem(4) rem(8);
    text-transform: capitalize;
    width: fit-content;
    color: $color-success-message;
    background: $status-active-gradient;
  }

  .ticket-list {
    margin-bottom: rem(12);

    &:first-child {
      margin-top: rem(16);
    }
  }
}
