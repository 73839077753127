@import 'styles/functions';
@import 'styles/colors';

.custom-tables-or-graphs {
  &.has-layouts {
    margin-bottom: rem(24);

    .row-actions-wrapper {
      width: 100%;
      height: rem(2);
      background: $color-grey-14;
      margin: rem(8) 0;
      position: relative;

      .row-actions {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -50%);
        padding: rem(6) rem(12);
        height: rem(28);
        border-radius: rem(8);
        background: $color-grey-15;
      }
    }
  }
}
