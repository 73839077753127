@import 'styles/functions';
@import 'styles/colors';

.optimization-table {
  padding: rem(16);
  margin: rem(16);
  border: rem(1) solid $color-grey-13;
  border-radius: rem(12);

  .graph-header-item {
    align-items: center;

    .expand-icon {
      gap: rem(16);

      .download-icon {
        margin-right: 0;
      }
    }
  }
  .status-bar {
    margin: rem(16) 0;
    color: $color-secondary-black;
    &-menu {
      &-item {
        padding: rem(8) rem(12);
        border: rem(1) solid $color-grey-13;
        transition: all 0.3s;

        &:first-child {
          border-top-left-radius: rem(12);
          border-bottom-left-radius: rem(12);
        }

        &:last-child {
          border-top-right-radius: rem(12);
          border-bottom-right-radius: rem(12);
        }

        &.selected {
          color: $color-primary-button;
          border-color: $color-primary-button;
        }
      }
    }
  }

  .tbc {
    color: $color-secondary-purple;
    background: $ticket-tbc-gradient;
    padding: rem(2) rem(12);
    border-radius: rem(16);
  }

  .status {
    border-radius: rem(6);
    padding: rem(4) rem(8);
    text-transform: capitalize;
    width: fit-content;

    &.approved {
      color: $color-success-message;
      background: $status-active-gradient;
    }

    &.disapproved {
      color: $color-status-deactivate;
      background: $status-deactivate-gradient;
    }
  }

  .ant-table-wrapper {
    .ant-table {
      &-container {
        .ant-table-content {
          height: calc(100vh - 24em);
        }
        .ant-table-body {
          height: calc(100vh - 24em);
        }
      }
    }

    & .ant-table-tbody > tr.ant-table-row-selected > td {
      background: $color-white;
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-body
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }
    &.design-v2
      .ant-table.ant-table-empty
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: none;
        }
      }
    }
  }
}
