@import 'styles/colors';
@import 'styles/functions';

.expand-modal {
  .ant-modal-content {
    border-radius: rem(12);

    .ant-modal-body {
      .ant-table-wrapper.design-v2.fill-container {
        max-height: calc(100% - 2em);
      }

      .custom-table-or-chart-data {
        .ant-table {
          .ant-table-body {
            max-height: calc(100vh - rem(200)) !important;
          }
        }
      }

      .graph-card {
        height: 100%;

        .graph-heading {
          .previous-icon {
            color: var(--button-icon-color);
            height: rem(16);
            width: rem(16);
          }

          .sub-title {
            color: $color-grey-5;
          }
        }

        .filters {
          margin-top: rem(8);
          gap: 1rem;

          .ant-select {
            width: 20%;
          }

          .ant-picker {
            width: 20%;
          }
        }

        .graph {
          height: 90%;
          margin-top: rem(16);

          &.no-filters {
            height: 98%;
          }

          .graph-area {
            height: 100%;
          }

          .ant-table {
            .ant-table-body {
              max-height: calc(100vh - rem(230)) !important;
            }
          }

          .ant-table :has(.ant-table-summary) > .ant-table-body {
            max-height: calc(100vh - rem(280)) !important;
          }

          .tabular-view {
            width: 100%;
            height: 100%;
            max-height: 100%;
            padding: rem(10);
          }
        }
      }
    }
  }

  .expand-hide {
    display: none;
  }
}
