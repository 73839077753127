@import 'styles/functions';

.integration-form {
  height: calc(100vh - 6.8em);

  .progress-bar {
    padding-top: rem(32);
    overflow-y: scroll;
  }

  .form-content {
    overflow-y: scroll;
    padding: 8%;
  }
}
