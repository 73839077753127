@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/variables';

.manual-entry-form {
  .manual-entry-tabs {
    margin: rem(16);

    .ant-tabs-content-holder {
      height: calc(100vh - 15em);

      .manual-entry-tab-container {
        .manual-entry-table {
          height: calc(100vh - 21em);
        }

        .page-footer {
          margin-bottom: rem(16);
        }
      }
    }
  }
}

@mixin manual-entry-form {
  .ant-input,
  .ant-input-number,
  .ant-picker {
    height: rem(36);
  }

  .ant-input-affix-wrapper {
    height: rem(36);

    .ant-input,
    .ant-input-prefix {
      height: rem(32);
    }
  }

  .ant-col {
    .ant-select {
      .ant-select-selector {
        height: rem(36);
      }
    }
  }

  .duration {
    &.ant-input-number {
      border-right: none;
    }

    .ant-input-number-group-addon {
      background-color: $color-grey-0;
      border-radius: 0 rem(10) rem(10) 0;
      color: $color-grey-5;
    }
  }

  .duration:hover,
  .duration-focus {
    .ant-input-number-group-addon {
      border-color: $color-primary-green;
    }
  }
}

.row-modal {
  .ant-drawer-wrapper-body {
    .ant-drawer-body {
      @include manual-entry-form;

      .horizontal-divider {
        margin: 0;
      }
    }
  }
}
