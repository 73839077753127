@import 'styles/functions';

.treemap {
  overflow-y: auto;
  overflow-x: hidden;

  .plot {
    overflow: hidden;
  }
}
