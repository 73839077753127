@import 'styles/functions';
@import 'styles/colors';

.scorecard-summary-dashboard {
  #graph-container {
    border: 0.5px solid $color-grey-13;
    border-radius: rem(12);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    transition: 0.3s;
  }

  .graph-card {
    height: rem(296);
    padding: rem(16);
    overflow: hidden;
    display: flex;
    flex-direction: column;

    .graph {
      height: 100%;
      overflow: hidden;
      margin-top: rem(16);
    }

    &.savings-effectiveness-summary {
      .gauge-graph {
        height: rem(200);
      }
    }
  }
}
