@import 'styles/functions';
@import 'styles/colors';

.provider-list {
  .back-arrow {
    color: var(--button-icon-color);
  }

  .provider-list-body {
    .ant-btn-primary {
      width: rem(523);
    }
    .sub-header {
      color: $color-grey-9;
    }
  }
}
