@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.granulate-insights-dashboard-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  height: rem($page-header-height);
}
