@import 'styles/functions';

.my-report {
  .table-section {
    margin-top: rem(16);

    .provider-icon {
      height: rem(18);
      width: rem(18);
    }
  }
}
