@import 'styles/functions';
@import 'styles/fonts';
@import 'styles/global';

.purchase-compute-engine-form {
  .ant-drawer-header {
    .project-id {
      color: $color-grey-9;
    }
  }

  .ant-drawer-body {
    @extend .new-styled-scroll;
  }

  .no-of-gpus-input {
    width: rem(100);
    &.step-number-input {
      height: rem(38);
      .ant-input {
        height: auto;
      }
    }
  }

  .gpu-form-item {
    .font-caption {
      color: $color-grey-9;
    }
  }
}
