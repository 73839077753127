@import 'styles/functions';
@import 'styles/colors';

.add-layout-placeholder {
  height: rem(48);
  border-radius: rem(12);
  border: dashed $color-grey-13;

  .add-icon {
    color: $color-regent-grey;
  }
}

.layout-options {
  &.ant-tooltip {
    .ant-tooltip-arrow {
      display: none;
    }
  }
  &.ant-tooltip-placement-bottom {
    padding: 0;
  }
}
