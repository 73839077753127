@import 'styles/colors';

.add-resource-tag-values-drawer {
  .resource-name {
    color: $color-grey-5;
  }

  .tag-row {
    .tag-key {
      color: $color-secondary-black;
    }
  }
}
