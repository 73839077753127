@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.gProfiler-insights-dashboard-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  height: rem($page-header-height);

  .optimization-potential {
    padding: rem(2) rem(8);
    color: $color-success-message;
    background: $status-active-gradient;
    border-radius: rem(16);
  }
}
