@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/functions';

.date-picker-wrapper {
  .ant-picker-range-separator {
    @extend .font-caption;
    color: $color-regent-grey;
  }

  .suffix-icon {
    color: $color-regent-grey;
    height: rem(15);
    width: rem(15);
  }

  &.ant-picker-range .ant-picker-input > input {
    text-align: center;
  }
}
