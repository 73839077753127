@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';

.chat-box-modal-wrapper {
  .chat-box-modal {
    .ant-drawer-header {
      background-color: $color-grey-12;
      border-bottom: 1px solid $color-grey-10;

      .chat-box-heading {
        .bot-icon {
          width: rem(37);
          height: rem(34);
          font-weight: normal;
        }

        .select-chatbot-provider {
          .ant-select-selector {
            height: rem(24);
            padding: auto;
            color: $color-grey-9;
            width: rem(140);
            border: 1px solid $color-grey-10;
            border-radius: rem(10);
            background-color: $color-off-white;

            .ant-select-selection-item {
              padding-top: rem(5);
              @extend .font-small-bold;
            }
          }
        }

        .tokens-used-container {
          .token-icon {
            position: relative;

            padding: rem(6);
            background-color: $color-tulip-tree;
            border-radius: 50%;

            &::before {
              position: absolute;
              content: '';
              padding: rem(2);
              background-color: $color-off-white;
              transform: rotate(45deg);
              top: rem(4);
              left: rem(4);
            }
          }

          .label {
            color: $color-grey-9;
          }
        }

        .actions {
          color: var(--button-icon-color);

          .chat-bot-connection-dropdown {
            min-width: 60%;
            max-width: 70%;

            .ant-select .ant-select-selector {
              background-color: $color-white;
            }
          }
        }
      }
    }

    .ant-drawer-body {
      display: flex;
      flex-direction: column;

      .chat-box-container {
        overflow: hidden;

        .chat-box-header {
          text-align: center;

          .chat-bot-icon {
            color: $color-secondary-black;
          }

          .chat-box-sub-heading {
            color: $color-grey-9;
          }
        }

        .chat-box-conversations {
          max-height: calc(100% - 11em);
          overflow: auto;
          padding-right: rem(8);

          .recommended-queries {
            text-align: center;

            .query {
              padding: rem(12) rem(16);
              border-radius: rem(10);
              background: $color-grey-12;

              &::first-letter {
                text-transform: capitalize;
              }
            }
          }
        }

        .query-input {
          padding: rem(8) rem(16);
          border-radius: rem(12);
          background-color: $color-grey-12;

          .query-input-field {
            padding: 0;
          }
        }

        .cebee-bot-footer-note {
          font-style: italic;
        }
      }
    }
  }
}
