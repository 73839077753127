@import 'styles/functions';

.multi-select-dropdown-popup {
  padding: rem(4);

  .all-select-option {
    &.all-checked {
      color: var(--primary-color) !important;
      font-weight: 600;
    }
  }

  .ant-select-item-option {
    padding: 0;
    .ant-checkbox-wrapper {
      padding: rem(4);
    }
  }
}
