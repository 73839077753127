@import 'styles/colors';
@import 'styles/functions';
@import 'styles/mixins';

.info-card {
  min-height: rem(120) !important;
  height: rem(120) !important;

  &-item {
    border-right: 1px solid $color-grey-13;
    padding-left: rem(8);

    &-heading {
      color: $color-grey-5;
    }
    &-status {
      font-weight: 600;

      &.decrease {
        color: $color-fn-red;
      }
      &.increase {
        color: $color-bright-green;
      }
    }

    &:first-child {
      padding-left: 0;
    }
    &:last-child {
      border: none;
    }
  }
}
