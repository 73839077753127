@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.create-monitor-form {
  overflow: hidden;

  .create-monitor-form-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);

    .back-arrow {
      width: rem(38);
      color: var(--button-icon-color);
    }

    .title-section {
      margin-left: rem(0);
    }

    .create-button {
      margin-left: auto;
    }
  }

  .form-container {
    padding: rem(16) rem(12) rem(16) rem(16);

    .basic-details {
      width: 30%;
      box-shadow: 0px rem(14) rem(24) rgba(0, 0, 0, 0.08);
      border-radius: rem(12);
      padding: 0 rem(16) rem(16);
    }

    .setup-subscription {
      .subscriptions-validation {
        font-size: rem(16);
      }

      .subscription-list {
        overflow: auto;
        max-height: calc(100vh - 11em);
        margin-top: rem(24);
        padding-right: rem(4);

        .alert-subscription-form {
          .ant-divider {
            margin: rem(12) 0;
          }

          .recipients-list-item {
            height: rem(26);
            background-color: $color-grey-1;
            padding: rem(4) rem(8) rem(6);
            border-radius: rem(8);
          }

          .threshold-row {
            .ant-input-affix-wrapper {
              height: rem(32);
              .ant-input {
                height: rem(32);
                background: transparent;
              }
            }
            .ant-select {
              margin-top: rem(4);
            }
            .delete-button {
              margin-top: rem(4);
            }
          }
        }
      }

      .help-text {
        opacity: 0.5;
      }
    }
  }
}
