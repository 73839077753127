@import 'styles/functions';
@import 'styles/colors';

.aupto-pilot-savings {
  .graph-card {
    border: 1px solid $color-grey-13;
    border-radius: rem(12);
    padding: rem(16);
    height: rem(300);
    display: flex;
    flex-direction: column;

    .graph {
      height: 100%;
      flex: 1;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .graph-area {
        height: 100%;
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
