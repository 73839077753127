@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/variables';

.create-ruleset-form {
  .ruleset-form-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    min-height: rem($page-header-height);

    .back-arrow {
      width: rem(38);
      color: var(--button-icon-color);
    }

    .title-section {
      margin-left: rem(0);
    }

    .create-button {
      margin-left: auto;
    }
  }

  .form-container {
    padding: rem(24) rem(24) 0;

    .basic-details {
      width: 30%;
      box-shadow: 0px rem(14) rem(24) rgba(0, 0, 0, 0.08);
      border-radius: rem(12);
      padding: 0 rem(16) rem(16);
    }

    .rule-setup {
      margin-left: rem(8);

      .add-new-rule-cta,
      .add-new-value-cta {
        @extend .font-button-small;
        background-color: $color-highlighter-purple !important;
      }

      .ruleset-list-container {
        margin-top: rem(16);
        height: calc(100vh - 10em);
        overflow: auto;

        &::-webkit-scrollbar {
          width: rem(6);
          height: rem(6);
          background-color: $color-grey-2;
          border-radius: rem(8);
        }

        &::-webkit-scrollbar-thumb {
          background-color: var(--primary-color);
          box-shadow: none;
          border-radius: rem(8);
        }

        .ruleset-list {
          .rule-card {
            background-color: $color-grey-0;
            border: rem(0.5) solid $color-grey-1;
            border-radius: rem(12);
            padding: rem(16);
            margin-right: rem(10);
            overflow-x: hidden;
            .recomendation {
              margin-right: rem(-22) !important;
            }
            .statements {
              margin-right: -2.5rem;

              .map-line {
                height: rem(32);
                width: rem(16);
                border-left: rem(0.5) solid $color-grey-3;
                border-bottom: rem(0.5) solid $color-grey-3;
              }

              .statement-list {
                border: rem(1) solid $color-grey-1;
                border-radius: rem(12);
                padding: rem(16);
                .statement-row {
                  color: var(--text-color);
                  opacity: 0.9;

                  .conjuction {
                    font-style: italic;
                  }

                  .select-dropdown,
                  .input-text,
                  .ant-picker,
                  .multi-select-checkbox {
                    height: rem(32);
                    margin: 0 rem(12);
                  }

                  .select-dropdown,
                  .multi-select-checkbox {
                    min-width: rem(150);
                  }
                }

                .helping-text {
                  color: $color-grey-5;
                  display: inline-block;
                  margin: rem(4) 0;
                }
              }
            }
          }
        }
      }
    }
  }
}
