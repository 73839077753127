@import 'styles/functions';
@import 'styles/colors';

.cmdb-configuration-page {
  .new-page-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid rgba(188, 196, 205, 0.4);
    background-color: $color-white;
  }

  .cmdb-configuration-form {
    height: calc(100vh - 7em);

    .progress-bar {
      overflow-y: scroll;
    }

    .form-container {
      overflow: auto;

      .form-content {
        margin: rem(24) rem(48);
      }
    }
  }
}
