@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.data-center-container {
  .data-center-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .data-center-content {
    height: calc(100vh - 15em);

    .data-center-table {
      margin-top: rem(16);
    }
  }

  .page-footer {
    padding: 0 rem(24) rem(16);
  }
}
