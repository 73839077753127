@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.commitments {
  .purchased-component {
    border: 1px solid $color-grey-13;
    border-radius: rem(12);
    padding: rem(16);
  }

  .radio-button-options {
    height: rem(28);

    .ant-radio-button-wrapper {
      height: inherit;
      line-height: rem(28);

      .ant-radio-button {
        height: inherit;
      }
    }
  }

  .horizontal-nav-menu-tabs {
    z-index: 2;
    position: sticky;
    top: rem($page-header-height + $navigation-path-height);
  }
}
