@import 'styles/functions';
@import 'styles/colors';

.report-dimension-list {
  margin-top: rem(16);

  .empty-dimensions-message {
    color: $color-grey-5;
  }

  ul {
    &.draggable-dimension-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .dimension-item {
        padding-top: rem(12);

        .dimension-handle {
          padding-top: rem(2);
          cursor: grab;
        }

        .dimension-label {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
