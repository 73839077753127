@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.autopilot-savings-dashboard-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  height: rem($page-header-height);

  &-right {
    gap: rem(24);
  }
  .table-view-title {
    margin-right: rem(12);
  }

  .connection-dropdown {
    width: rem(250);
  }
}
