@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/functions';

.tags-container {
  .tags-and-labels {
    width: 65%;

    .tags-list {
      gap: rem(24);
      padding-right: rem(8);
      margin-bottom: rem(8);
      max-height: calc(100vh - 12em);
      overflow-y: auto;
    }
    .validation-message {
      padding-left: rem(16);
    }
  }
}
