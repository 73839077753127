@import 'styles/colors';
@import 'styles/functions';

.progress-bar {
  width: 39%;
  background: $color-bg-progress;
  border-right: rem(1) solid $color-grey-10;
  padding: rem(20);

  &-header {
    &-heading {
      color: $color-secondary-black;
      margin-bottom: rem(6);
    }
    &-sub-heading {
      color: $color-grey-9;
    }
  }

  .steps {
    margin-top: rem(32);

    .ant-steps-item-content {
      .ant-steps-item-description {
        padding-bottom: rem(32);
      }
    }
  }
}
