@import 'styles/functions';

.compute-sp-purchase-form {
  .ant-drawer-footer {
    padding: 0;

    .plan-cost-summary {
      padding: rem(12) rem(16);
    }

    .ant-divider {
      margin: 0;
    }

    .buy-modal-footer {
      padding: rem(12) rem(16);
    }
  }
}
