@import 'styles/functions';
@import 'styles/colors';

.card-container {
  height: rem(93);
  border: 2px solid $color-grey-1;
  border-radius: rem(12);
  cursor: pointer;

  &:hover,
  &.selected {
    border: 2px solid var(--primary-color);
  }

  .logo {
    width: rem(65);
    height: rem(65);
    margin: rem(14);
    border-radius: rem(10);
    background-color: $color-white;

    .icon {
      width: rem(43);
      height: rem(43);
    }
  }

  .title {
    letter-spacing: 0em;
    text-align: left;
  }

  .sub-title {
    max-width: rem(400);
    color: $color-grey-9;
  }

  &.disabled {
    pointer-events: none;
    .logo {
      filter: grayscale(1);
    }
  }
}
