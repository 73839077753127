@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.upload-tco-file {
  .upload-form {
    .ant-upload.ant-upload-drag {
      &.dragger {
        border-radius: rem(8);
        background: none;
        .dragger-content {
          margin: rem(24);
          .normal-icon {
            color: $color-grey-2;
          }
          .uploaded-icon {
            color: var(--primary-color);
          }
        }
      }
    }
  }

  .validation-message {
    .click-here-text {
      color: $color-highlighter-purple;
    }
  }
}
