@import 'styles/functions';
@import 'styles/colors';

.controls-container-header {
  border-bottom: $color-grey-10 1px solid;
  padding: rem(8) rem(24);
  color: $color-secondary-black;
  transition: 0.3s;
  height: auto;
  overflow: auto;

  .filter-container {
    &.default-width {
      width: rem(250);
    }

    .clear-button {
      color: $color-regent-grey;
    }
  }

  .minimized-filter-container {
    color: $color-grey-9;
  }

  .arrow-icon {
    transition: 0.3s;
    &.rotated {
      transform: rotate(180deg);
    }
  }
}
