@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.select-tag-connection-container {
  .select-tag-connection-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    min-height: rem($page-header-height);

    .back-arrow {
      color: var(--button-icon-color);
    }
  }

  .card {
    width: rem(520);
  }
  .submit-section {
    width: rem(520);
  }
  .error-message {
    width: rem(520);
  }
}
