@import 'styles/colors';
@import 'styles/functions';

.projects-dataset-dropdown {
  margin: rem(12);

  span {
    color: $color-secondary-black;
    &.selected {
      color: var(--button-icon-color);
    }
  }

  .dropdown-divider {
    border-left: 1px solid $color-grey-8;
    height: auto;
  }

  .chevron-right {
    color: $color-grey-5;
  }
}
