@import 'styles/colors';
@import 'styles/functions';

.graph-heading {
  .previous-icon {
    color: var(--button-icon-color);
    height: rem(16);
    width: rem(16);
  }

  .sub-title {
    color: $color-grey-5;
  }
}
