@import 'styles/colors';
@import 'styles/functions';

.waste-management {
  &.graph-card.tabular-view {
    height: 100% !important;

    .graph {
      height: 100% !important;

      .info-icon {
        color: $color-regent-grey;
        height: rem(18);
        width: rem(18);
      }
    }
  }
}

.waste-management-tooltip {
  max-width: rem(400);
}
