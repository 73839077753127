@import 'styles/functions';
@import 'styles/colors';

.report-chart-metrics {
  margin: 0 rem(8);

  .metric-validation-message {
    color: $color-grey-5;
  }
}
