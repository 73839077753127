@import '../../styles/functions';
@import '../../styles/colors';

.profile-container {
  width: rem(537);
  height: rem(463);
  padding: rem(24);
  background-color: $color-grey-0;
  border-radius: rem(12);

  .section {
    gap: rem(7);
  }

  .logo {
    height: rem(129);
    margin-bottom: auto;
    border-radius: 50%;
  }

  .input-section {
    gap: rem(34);

    .section {
      .value {
        border: 1px solid $color-grey-1;
        border-radius: rem(12);
        padding: rem(8);
      }
    }
  }
}
