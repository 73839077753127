@import 'styles/colors';
@import 'styles/functions';

.savings-per-category {
  &.selected {
    .category-graph {
      padding-right: rem(24);
    }
  }

  .savings-label {
    color: $color-grey-5;
  }

  .savings-value {
    color: $color-secondary-black;
  }
}
