@import 'styles/functions';
@import 'styles/colors';

.report-chart-dimensions {
  margin: 0 rem(8);

  .dimension-validation-message {
    color: $color-grey-5;
  }
}
