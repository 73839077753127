@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.dashboard-tag-content {
  padding-left: rem(12);
  .count {
    border-radius: rem(48);
    background-color: $color-grey-12;
    margin: 0 rem(8);
    width: rem(26);
    height: rem(18);
  }
}

.dashboard-tag-dropdown {
  .ant-select {
    width: rem(250);
  }
  &.ant-select-dropdown {
    width: rem(250) !important;
    .tag-dropdown-wrapper {
      padding: rem(12);
      .ant-input-affix-wrapper {
        width: rem(220);
        height: rem(32);
        > input {
          height: rem(30);
        }
      }
      .ant-select-item {
        padding-left: 0;
      }
      .ant-divider-horizontal {
        margin: rem(16) 0;
      }
      .ant-btn-primary {
        height: rem(24);
        border-radius: rem(6);
        display: flex;
        align-items: center;
        > div {
          @extend .font-caption-bold;
        }
      }
    }
  }
}
