@import 'styles/functions';
@import 'styles/colors';

.error-component {
  .footer-info {
    .ant-btn {
      padding-left: rem(4);
      padding-right: rem(4);
    }
  }
  .sub-title {
    color: $color-error-message;
  }
  .footer-info,
  .dashboard-button {
    margin-top: rem(16);
  }
  .error-message {
    max-width: 40%;
    overflow-wrap: break-word;
  }
}
