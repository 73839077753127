@import 'styles/colors';
@import 'styles/functions';

.import-file-form {
  .title-container {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .import-file-form-content {
    height: calc(100vh - 6.8em);

    .progress-bar {
      padding-top: rem(32);
      overflow-y: scroll;
    }

    .form-content {
      overflow-y: scroll;
      padding: 8%;
    }

    .provider-footer {
      padding: rem(16);
    }
  }
}
