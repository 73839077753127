@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.acknowledgment {
  overflow: hidden;

  .acknowledgment-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    min-height: rem($page-header-height);
  }

  .licence-container {
    overflow: auto;
    padding: rem(24);

    .quotation {
      padding-left: rem(16);
    }

    .libraries {
      margin-top: rem(10);
    }
  }
}
