@import 'styles/colors';
@import 'styles/functions';

.cost-allocation-dashboard-title {
  .title-container {
    gap: rem(6);

    .edit {
      gap: rem(6);
    }
  }

  .sub-title-container {
    gap: rem(4);
  }
}
