@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.appearance-page {
  .appearance-page-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .appearance-container {
    padding: rem(24);

    .appearance-form-content {
      margin-left: auto;
      margin-right: auto;
      width: 50%;
      gap: 0;

      .sub-heading {
        opacity: 0.5;
      }

      .color-disc-group {
        margin-right: rem(16);
      }

      .ant-input-prefix {
        margin-right: rem(8);
      }

      .ant-upload.ant-upload-drag {
        &.logo-dragger-area {
          border-radius: rem(8);
          background: none;
          .dragger-content {
            margin: rem(24);
            .normal-icon {
              color: $color-grey-2;
            }
            .uploaded-icon {
              color: var(--primary-color);
            }
          }
        }
      }

      .ant-divider-horizontal {
        margin: rem(16) 0;
      }
    }
  }
}

.color-picker-popover {
  &.ant-popover-placement-bottomLeft {
    padding-top: 0;
    .ant-popover-arrow {
      display: none;
    }
    .ant-popover-inner {
      border-radius: rem(8);
    }
  }

  &.static-colors-popover {
    width: rem(264);
  }
}

.color-disc,
.color-disc-large {
  width: rem(16);
  height: rem(16);
  border-radius: 50%;
  border: 1px solid $color-light-black;
  margin: auto 0;
}

.color-disc-large {
  width: rem(24);
  height: rem(24);
}
