@import 'styles/functions';
@import 'styles/colors';

.share-report-team-row {
  .team-pic {
    height: rem(34);
    width: rem(34);
    border-radius: rem(12);
    background-color: $color-grey-15;
    color: $color-grey-5;
  }

  .permission-dropdown {
    width: rem(96);
  }

  .remove-icon {
    color: $color-regent-grey;
    width: rem(18);
  }
}
