@import 'styles/functions';
@import 'styles/fonts';

.memory-db-ri-purchase-form {
  .step-number-input {
    height: rem(32);
    .ant-input {
      height: auto;
    }
  }
}
