@import 'styles/functions';
@import 'styles/colors';

.upload-csv-container {
  width: 60%;
  margin: 1rem;

  .ant-upload.ant-upload-drag {
    &.dragger {
      border-radius: rem(8);
      background: none;
      .dragger-content {
        margin: rem(24);
        .normal-icon {
          color: $color-grey-2;
        }
        .uploaded-icon {
          color: var(--primary-color);
        }
      }
    }
  }
}
