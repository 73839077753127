@import 'styles/functions';

.dashboard-content {
  .gcp-detailed-savings-table.graph-card {
    height: rem(400);
  }
}

.expand-modal
  .ant-modal-content
  .ant-modal-body
  .graph-card
  .graph
  .ant-table
  .ant-table-body {
  max-height: calc(100vh - 21em) !important;
}
