@import 'styles/functions';
@import 'styles/variables';
@import 'styles/colors';

.menu-manager {
  width: rem($nav-menu-icons-only-width);
  background-color: $color-off-white;
  border-right: 1px solid $color-grey-10;
  z-index: 4;
  transition: 0.3s;
  position: relative;

  .main-menu {
    width: inherit;
  }

  &.show-menu-title {
    width: rem($nav-menu-short-width);
  }

  &.with-sub-menu {
    width: rem($nav-menu-open-width);
    border-right: none;

    .main-menu {
      width: rem($nav-menu-short-width);
    }
  }
}
