// Add global styles here
@import './colors';
@import './flex';
@import './grid';
@import './fonts';
@import './antdOverrides';
@import './functions';
@import './variables';

@import url('https://fonts.googleapis.com/css2?family=Open+Sans');

#root {
  height: 100vh;
  width: 100vw;
  font-size: 16px;

  // Allows textarea tags under root div element
  textarea {
    display: block !important;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  // Blocks the textarea outside the root element due to inline styles being blocked as part of CSP
  textarea {
    display: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

// Header Classes
.main__header {
  @include font($fontSize: 24px, $fontWeight: 600, $line-height: 33px);
}

.stretch {
  height: 100%;
  width: 100%;
}
.full-height {
  height: 100%;
}

.required-asterisk {
  &:after {
    content: ' *';
    color: $color-fn-red;
  }

  &.disabled:after {
    content: ' *';
    color: $color-grey-5;
  }
}

.hide-scroll {
  &::-webkit-scrollbar {
    display: none;
  }
}

.cursor-disabled {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.pointer-events-none {
  pointer-events: none;
}

.display-none {
  display: none;
}

.selected-users-list {
  gap: rem(7);
  &-under-dropdown {
    gap: rem(5);
    height: rem(26);
    background-color: $color-grey-0;
    padding: rem(4) rem(8) rem(6);
    border-radius: rem(8);
  }
}

.rotate {
  animation: rotation 2s linear infinite;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.loading-text {
  clip-path: inset(0 1ch 0 0);
  animation: loading 1s steps(5) infinite;
}

@keyframes loading {
  to {
    clip-path: inset(0 -2ch 0 0);
  }
}

.styled-scroll {
  ::-webkit-scrollbar {
    width: 3px;
    height: 5px;
    background-color: $color-grey-2;
  }

  ::-webkit-scrollbar-thumb {
    background-color: var(--primary-color);
    box-shadow: none;
  }
}

.rounded-styled-scroll {
  ::-webkit-scrollbar {
    width: rem(8);
    background-color: $color-grey-1;
    border-radius: rem(20);
  }

  ::-webkit-scrollbar-thumb {
    border-radius: rem(20);
    background-color: var(--primary-color);
    box-shadow: none;
  }
}

.new-styled-scroll {
  &::-webkit-scrollbar {
    width: rem(8);
    height: rem(8);
    background-color: $color-grey-12;
    border-radius: rem(20);
  }

  &::-webkit-scrollbar-thumb {
    border-radius: rem(20);
    background-color: $color-scroll-bar;
  }
}

.text-align-center {
  text-align: center;
}

.error-message {
  border: 1px solid $color-error-bg;
  border-radius: rem(12);
  padding: rem(8);
  color: $color-error-message;
  background-color: $color-error-bg;
}

.success-message {
  border: 1px solid $color-bright-teal;
  border-radius: rem(12);
  padding: rem(8);
  color: $color-success-message;
  background-color: $color-bright-teal;
}

.full-width {
  width: 100%;
}

.width-100 {
  width: 100%;
}

.width-10 {
  width: 10%;
}

.width-15 {
  width: 15%;
}

.width-25 {
  width: 25%;
}

.width-30 {
  width: 30%;
}

.width-40 {
  width: 40%;
}

.width-50 {
  width: 50%;
}

.width-60 {
  width: 60%;
}

.width-70 {
  width: 70%;
}

.position-relative {
  position: relative;
}

.position-absolute {
  position: absolute;
}

.tag {
  color: $color-dark-orange;
  background-color: $color-medium-orange;
  padding: rem(4) rem(7);
  border-radius: rem(6);
}

.white-app-logo {
  color: $color-white;
}

.black-app-logo {
  color: $color-pure-black;
}

.tick-success {
  color: $color-primary-green;
}

.tick-error {
  color: $color-fn-orange;
}

.tick-error,
.tick-success {
  &.disabled {
    color: $color-grey-1;
  }
}

.page-footer {
  position: sticky;
  bottom: 0;
  z-index: 2;
}

.validation-success {
  @extend .font-validation-error;
  color: $color-primary-button;
}

.margin-24 {
  margin: rem(24);
}

// TODO: This is a temporary fix for the margin issue for provider forms for old designs.
// Remove this once the new designs are implemented.
.vertical-margin-24 {
  margin: rem(24) 0;
}

.status {
  border-radius: rem(16);
  padding: rem(4) rem(8);

  @extend .font-small-bold;

  &.payment-pending,
  &.active,
  &.retired,
  &.queued,
  &.queued-deleted {
    color: $color-success-message;
    background: $status-active-gradient;
  }

  &.uploading,
  &.processing {
    color: $color-fn-orange;
    background: $color-medium-orange;
  }

  &.payment-failed,
  &.error,
  &.deactivated {
    color: $color-status-deactivate;
    background: $status-deactivate-gradient;
  }
}
