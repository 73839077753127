@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.app-layout {
  position: relative;

  .main-section {
    transition: 0.3s;
    position: absolute;
    top: 0;
    background-color: $color-white;

    .new-page-header {
      background-color: $color-white;
      width: calc(100% - rem($nav-menu-icons-only-width));
      margin-left: rem($nav-menu-icons-only-width);
      min-height: rem($page-header-height);
      z-index: 3;
      position: sticky;
      top: 0;
    }

    .page-content {
      width: calc(100% - #{$nav-menu-icons-only-width}px);
      margin-left: rem($nav-menu-icons-only-width);
    }

    &.menu-with-title {
      .new-page-header {
        width: calc(100% - rem($nav-menu-short-width));
        margin-left: rem($nav-menu-short-width);
      }

      .page-content {
        width: calc(100% - #{$nav-menu-short-width}px);
        margin-left: rem($nav-menu-short-width);
      }
    }

    &.nav-pinned {
      .new-page-header {
        width: calc(100% - rem($nav-menu-open-width));
        margin-left: rem($nav-menu-open-width);
      }

      .page-content {
        width: calc(100% - #{$nav-menu-open-width}px);
        margin-left: rem($nav-menu-open-width);
      }
    }

    .app-content {
      margin: rem(21) rem(24);
      overflow: auto;

      &.margin-less-content {
        margin: 0;
      }
    }
  }
}
