@import 'styles/functions';

.granulate-usage-metering {
  .savings-types {
    height: rem(28);

    .ant-radio-button-wrapper {
      height: inherit;
      line-height: rem(28);

      .ant-radio-button {
        height: inherit;
      }
    }
  }
}
