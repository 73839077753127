@import 'styles/functions';
@import 'styles/colors';

.chat-conversations {
  .model-change-content {
    .ant-divider {
      width: 15%;
      min-width: unset;
    }
  }

  .chat-content {
    .chat {
      .profile-icon,
      .bot-icon {
        width: rem(24);
        height: rem(24);
        border-radius: rem(4);
      }

      .bot-icon {
        color: $color-secondary-black;
        flex: none;
      }

      .chat-message {
        padding: rem(12) rem(16);
        border-radius: rem(12);
        overflow-wrap: anywhere;
        white-space: pre-wrap;
      }

      .loading-skeleton {
        flex: none;
      }
    }

    &.user {
      margin-left: 10%;

      .chat {
        .chat-message {
          border-top-right-radius: 0;
          background-color: $color-grey-12;
        }
      }
    }

    &.bot {
      margin-right: 10%;

      .chat {
        .chat-message {
          border-top-left-radius: 0;
          background-color: $color-bg-light-green;
        }
      }
    }
  }
}
