@import 'styles/functions';
@import 'styles/colors';

.sort-list {
  margin-top: rem(12);

  .empty-sort-message {
    color: $color-grey-5;
  }
}
