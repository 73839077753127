@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.aws-ec2-ri-cart {
  .cart-heading-subtitle {
    color: $color-grey-9;
  }

  .cart-list {
    overflow: auto;
    height: calc(100vh - 18em);
  }

  .cart-footer {
    width: calc(100% - #{$nav-menu-short-width}px);
    position: absolute;
    bottom: 0;
    padding: rem(10) rem(24);
    margin: 0 rem(-24);
    background-color: $color-off-white;
    border-top: rem(1) solid $color-grey-13;

    &.nav-pinned {
      width: calc(100% - #{$nav-menu-open-width}px);
    }
  }
}
