@import 'styles/functions';
@import 'styles/colors';

.share-report-user-row {
  .user-pic {
    height: rem(34);
    width: rem(34);
    border-radius: rem(12);
  }

  .email {
    color: $color-grey-5;
  }

  .permission-dropdown {
    width: rem(96);
  }

  .remove-icon {
    color: $color-regent-grey;
    width: rem(18);
  }
}
