@import 'styles/functions';

.carbon-emission-by-regions {
  &#graph-container {
    .filters {
      .ant-select {
        width: 15%;
      }
    }
  }
}
