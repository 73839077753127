@import 'styles/colors';
@import 'styles/functions';

.role-permissions,
.custom-role-form {
  .ant-modal-header {
    padding: rem(16) rem(24) 0;
  }

  .permissions-table {
    tr:nth-child(2n) td {
      background-color: $color-grey-0;
    }
    tr:nth-child(2n + 1) td {
      background-color: $color-grey-0;
    }

    .ant-checkbox-checked {
      &.ant-checkbox-disabled {
        .ant-checkbox-inner {
          background-color: $color-grey-5;
          border-radius: rem(4);
        }
        .ant-checkbox-inner:after {
          border-color: $color-white;
        }
      }
      .ant-checkbox-inner {
        border-radius: rem(4);
      }
      .ant-checkbox-inner:after {
        border-color: $color-white;
      }
    }
  }
}
