@import 'styles/functions';
@import 'styles/colors';

.teams-table {
  margin: rem(16) rem(24);

  .table-section {
    margin-top: rem(16);

    .users-count {
      color: $color-grey-5;
    }

    .status {
      text-transform: capitalize;
      overflow-wrap: normal;
    }
  }
}
