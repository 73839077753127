@import 'styles/functions';
@import 'styles/colors';

.filter-list {
  margin-top: rem(12);

  .empty-filter-message {
    color: $color-grey-5;
  }
}
