@import 'styles/functions';

.connection-dependency-disclaimer {
  .ant-modal-content {
    padding-right: 0;
  }

  .ant-modal-header,
  .ant-modal-body,
  .ant-modal-footer {
    padding-right: rem(24);
  }

  .ant-modal-body {
    max-height: calc(100vh - 18em);
    overflow: scroll;
  }
}
