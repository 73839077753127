@import 'styles/functions';
@import 'styles/colors';

.cost-allocation-form {
  overflow-y: hidden;
  .cost-allocation-page-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid rgba(188, 196, 205, 0.4);
    background-color: $color-white;
  }

  .allocation-form-wrapper {
    height: calc(100vh - rem(80));
    .form-content {
      padding: 8%;
      overflow: auto;

      &.business-unit-page {
        padding: rem(24);
      }

      .form-header > img {
        width: rem(32);
        height: rem(32);
      }

      .pending-message {
        color: $color-grey-9;
      }

      .delete-btn {
        color: $color-fn-red;
      }
    }

    .pending-message {
      color: $color-grey-9;
    }

    .delete-btn > div {
      color: $color-delete-button;
    }

    .fetch-cost-allocation-data {
      padding: rem(8);
      background-color: $color-bg-progress;
      color: $color-fn-orange;
      border-radius: rem(12);
    }

    .business-unit-list {
      height: calc(100vh - 23em);

      .pending-cost {
        color: $color-fn-red;
      }
    }
  }
}
