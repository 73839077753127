@import 'styles/functions';

.tco-create-project-modal {
  .ant-modal-content {
    .ant-divider {
      &.ant-divider-horizontal {
        margin: 0;
      }
    }
  }
}
