@import 'styles/functions';
@import 'styles/colors';

.tag-item {
  .tag-checkbox {
    width: rem(18);
    height: rem(18);
    border: 1px solid $color-grey-1;
    border-radius: rem(4);
  }

  &.active {
    .tag-checkbox {
      border: 1px solid var(--button-icon-color);
    }

    .tag-value {
      color: var(--button-icon-color);
    }
  }

  &.checked {
    .tag-checkbox {
      border: 1px solid var(--button-icon-color);
      background-color: var(--button-icon-color);
      color: $color-white;
    }
  }
}
