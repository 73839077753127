@import 'styles/functions';
@import 'styles/colors';

.subscriptions {
  .subscriptions-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .provider-icon {
    height: rem(18);
    width: rem(18);
  }
}
