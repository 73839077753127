@import 'styles/functions';

.savings-effectiveness-scorecard-dashboard {
  .graph-card {
    height: rem(400);
    overflow: hidden;

    .filters {
      display: flex;
      gap: rem(16);
      margin-top: rem(8);

      .ant-select {
        width: 15%;
      }
    }

    .graph {
      height: rem(330);
      margin-top: rem(16);
    }
  }
}
