@import 'styles/functions';

.custom-role-modal {
  .ant-modal-content {
    .ant-modal-body {
      .custom-role-body {
        gap: rem(16);

        .custom-role-form {
          gap: rem(24);
        }
      }
    }
  }
}
