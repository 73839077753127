@import 'styles/functions';
@import 'styles/colors';
@import 'styles/global';

.spend-diagnostics-tags-modal {
  .tag-value-search {
    width: rem(264);
  }

  .tags-collapse {
    gap: rem(16);

    .tag-panel {
      background-color: transparent;
      border: none;

      &.ant-collapse-item-active {
        border: none;
      }

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          padding-right: rem(6);

          .tag-collapse-arrow {
            color: $color-grey-9;
          }
        }

        .values-count {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }

      .ant-collapse-content {
        background-color: inherit;
        border: none;
        margin: 0 0 0 rem(26);

        .ant-collapse-content-box {
          padding: rem(8) 0 0;
        }
      }
    }
  }

  .ant-drawer-body {
    @extend .new-styled-scroll;

    .values-list {
      .ant-checkbox-wrapper > span:nth-child(2) {
        word-wrap: break-word;
        width: 100%;
      }
    }
  }
}
