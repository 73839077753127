@import 'styles/colors';
@import 'styles/functions';

.sub-menu {
  width: rem(237);
  background: $green-gradient-selection-secondary;
  z-index: 3;
  overflow-y: auto;
  overflow-x: hidden;

  .sub-menu-heading {
    position: sticky;
    top: 0;
    background: $green-gradient-selection-secondary;
    padding: rem(16);
    z-index: 4;

    .sub-menu-heading-title {
      color: $color-grey-9;
      text-transform: uppercase;
    }
  }

  .sub-menu-items {
    margin-left: rem(4);
    padding: 0 rem(16) rem(16);

    .sub-section-item,
    .sub-menu-item {
      color: $color-secondary-black;
      padding: rem(8) rem(12);
      border-radius: rem(12);
      transition: all 300ms;

      .menu-icon-container {
        height: rem(24);
        width: rem(24);

        .menu-icon {
          margin-right: rem(8);
        }
      }

      .arrow-icon {
        transition: all 0.3s;
        &.expanded {
          transform: rotate(180deg);
        }
      }
    }

    .sub-section-item {
      &.selected {
        background: $light-gradient;
        color: $color-white;
      }
    }

    .sub-menu-item {
      &.selected {
        color: var(--primary-color);
      }

      &.expanded {
        .sub-menu-title {
          color: $color-secondary-black;
        }
      }
    }
  }
}
