// Antd Design Overrides
@import './colors';
@import './mixins';
@import './functions';
@import './fonts';

//Button Styles Overrides
.ant-btn {
  box-shadow: none;
  &.ant-btn-primary {
    border: none;
    border-radius: rem(12);
    @extend .font-button;
  }
  &.ant-btn-neutral {
    border: none;
    border-radius: rem(12);
    @extend .font-button;
  }
  &.ant-btn-outlined {
    border-radius: rem(12);
    @extend .font-button;
  }

  &.ant-btn-link {
    @extend .font-link-bold;
  }

  &.btn-small {
    height: rem(32);
  }
  &.btn-medium {
    height: rem(36);
  }
  &.btn-large {
    height: rem(40);
  }

  &.btn-disabled {
    opacity: 0.6;
  }
}

// File Upload Styles Overrides
.ant-upload-select {
  border-radius: rem(10);
}

.ant-upload {
  color: var(--text-color);

  &.ant-upload-drag:not(.ant-upload-disabled):hover {
    border-color: var(--primary-color);
  }
}

//Input Styles Overrides
.ant-input-affix-wrapper {
  width: 20rem;
  border: 0.5px solid $color-grey-1;
  border-radius: 0.7rem;
  height: rem(32);
  padding: 0 rem(11);

  @extend .font-button;
  color: var(--text-color);
  font-weight: 400;

  .ant-input-suffix .search-icon {
    color: $color-regent-grey;
  }

  &.step-number-input {
    .ant-input-prefix,
    .ant-input-suffix {
      color: $color-regent-grey;
    }

    .ant-input {
      text-align: center;
    }
  }
}

.ant-input-password {
  width: 100%;
}

.ant-input {
  border-radius: rem(10);
  color: var(--text-color);
  background-color: transparent;

  &.ant-input-disabled {
    background-color: transparent;
  }
}

// Number input overrides
.ant-input-number,
.ant-input-number-affix-wrapper {
  display: flex;
  align-items: center;
  border-radius: rem(10);
  width: 100%;
  color: var(--text-color);
  background-color: transparent;

  .ant-input-number-handler-wrap {
    display: none;
  }

  .ant-input-number-input-wrap {
    width: 100%;
  }
}

.ant-input:hover,
.ant-input-affix-wrapper:hover,
.ant-input-affix-wrapper-focused,
.ant-input:focus,
.ant-input-number:hover,
.ant-input-number-focused {
  border-color: var(--primary-color) !important;
  box-shadow: none !important;
}

.ant-input,
.ant-input-group-wrapper,
.ant-input-number,
.ant-input-password,
.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
  &.input-small {
    height: rem(32);
    .ant-input {
      height: rem(30);
    }
  }

  &.input-medium {
    height: rem(38);
    .ant-input {
      height: rem(36);
    }
  }
}

// Pagination Styles Overrides

.ant-pagination {
  margin-left: auto;
  @extend .font-button;
  font-weight: 600;
  color: var(--text-color);
  & .ant-pagination-prev {
    margin-right: 0;
    & .ant-pagination-item-link {
      border-radius: 12px 0 0 12px;
    }
  }

  & .ant-pagination-prev:hover,
  & .ant-pagination-next:hover {
    & .ant-pagination-item-link {
      border-color: var(--primary-color);

      .anticon {
        color: var(--text-color);
      }
    }
  }

  & .ant-pagination-disabled:hover {
    & .ant-pagination-item-link {
      border-color: $color-grey-1;

      .anticon {
        color: $color-grey-1;
      }
    }
  }

  & .ant-pagination-item {
    margin-right: 0;
    a {
      color: var(--primary-color);
    }
  }
  & .ant-pagination-next {
    & .ant-pagination-item-link {
      border-radius: 0 12px 12px 0;
    }
  }
  & .ant-pagination-item-active {
    background-color: var(--primary-color);
    border: 0;
    a {
      color: $color-white;
    }
  }

  .ant-pagination-options {
    display: none;
  }

  & .ant-pagination-item:hover {
    border-color: var(--primary-color);
    a {
      color: var(--primary-color);
    }
  }
}

// Table Styles Overrides
.design-v1 {
  .ant-table {
    background-color: $color-grey-0;
    color: var(--text-color);
    @extend .styled-scroll;
    table {
      border-spacing: 0;
      width: 100%;

      .ant-table-row-expand-icon:hover,
      .ant-table-row-expand-icon:focus,
      .ant-table-row-expand-icon:active {
        color: var(--primary-color);
      }

      .ant-table-thead > tr > th {
        height: rem(38);
        padding: rem(4);
        @extend .font-button;
        color: var(--text-color);
        background-color: var(--table-header-color);
      }

      .ant-table-thead .ant-table-cell {
        @extend .font-button;

        .ant-table-filter-trigger.active {
          color: var(--primary-color);
        }
      }

      thead > tr > th {
        background-color: var(--table-header-color);
        border-bottom: none;
      }

      thead > tr:first-child > th:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      thead > tr:first-child > th:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      th {
        &.ant-table-cell {
          .ant-table-filter-column {
            width: 100%;
            justify-content: flex-start;
            .ant-table-column-title {
              flex: 0;
              z-index: 0;
            }
          }

          &.ant-table-column-sort,
          &.ant-table-column-has-sorters {
            background-color: var(--table-header-color);
            .ant-table-column-sorters {
              width: 100%;
              justify-content: center;
              display: inline-block;
              .ant-table-column-title {
                flex: 0;
                z-index: 0;
              }
              .ant-table-column-sorter-inner {
                .anticon-caret-up,
                .anticon-caret-down {
                  &.active {
                    color: var(--primary-color);
                  }
                }
              }
            }
          }
          &.ant-table-column-has-sorters:hover {
            background-color: var(--table-header-color);
          }
        }
      }

      .ant-table-thead > tr > th:first-child,
      .ant-table-thead > tr > th:last-child {
        text-align: center;
      }

      .ant-table-tbody > tr > td {
        height: rem(38);
        padding: 4px;
        border-bottom: rem(1) solid $color-grey-1;
      }

      .ant-table-expanded-row > .ant-table-cell {
        border-bottom: none;
      }

      .ant-table-tbody {
        tr {
          .ant-table-cell {
            @extend .font-button;
            &:not(th) {
              @include font($fontWeight: 400);
            }
          }
          & td:last-child {
            text-align: center;
          }

          &.ant-table-row-selected > td {
            background: transparent;
          }

          .ant-radio-wrapper {
            .ant-radio-checked {
              .ant-radio-inner,
              &.ant-radio-checked::after {
                border-color: var(--button-icon-color);
                background-color: var(--button-icon-color);
              }
            }

            .ant-radio-inner::after {
              background-color: $color-white;
            }

            &.ant-radio-wrapper:hover .ant-radio,
            .ant-radio:hover .ant-radio-inner,
            .ant-radio-input:focus + .ant-radio-inner {
              border-color: var(--button-icon-color);
            }
          }
        }

        .ant-table-row-expand-icon-cell {
          color: $color-grey-4;
        }

        .ant-table-expanded-row > td {
          background-color: $color-grey-0;
        }
      }
      .ant-table-tbody > tr > td:first-child {
        text-align: center;
      }

      td:first-child {
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
      }

      td:last-child {
        border-top-right-radius: 10px;
        border-bottom-right-radius: 10px;
      }

      .ant-table-summary > tr {
        background-color: var(--table-header-color);
        td {
          padding: rem(8);
        }
      }
    }

    tfoot {
      @extend .font-button;
    }
  }
}

// Table Styles Overrides
.ant-table-wrapper.design-v2 {
  table {
    border-radius: 0;
    border-color: $color-grey-13;
  }

  .ant-table.ant-table-bordered {
    border-radius: 0;
    background: transparent;

    .ant-table-container {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
      border-color: $color-grey-13;
      border: 1px solid $color-grey-13;

      .ant-table-header {
        border-radius: 0;

        table {
          background-color: $color-grey-12;

          @include ant-table-heading;
        }
      }

      @include ant-table-heading;

      .ant-table-body {
        @extend .new-styled-scroll;

        table {
          @include ant-table-body;
        }
      }
      .ant-table-content {
        @include ant-table-body;
      }
    }

    .ant-table-footer {
      border-color: $color-grey-13;
    }
  }

  .ant-table-summary {
    z-index: 1;
    > tr {
      background-color: $color-grey-12;

      > td {
        background-color: $color-grey-12;
      }
    }
  }

  .ant-table-footer {
    background-color: $color-grey-12;
  }

  &.fill-container {
    height: inherit;
    display: flex;
    flex-direction: column;
    flex: auto;

    .ant-spin-nested-loading {
      height: 100%;
      display: flex;
      flex-direction: column;
      flex: auto;

      .ant-spin-container {
        height: 100%;
        display: flex;
        flex: auto;
        flex-flow: column nowrap;

        .ant-table {
          display: flex;
          flex-direction: column;
          flex: auto;
          height: 100%;
          overflow: hidden;

          .ant-table-container {
            height: 100%;
            display: flex;
            flex: 1;
            flex-flow: column nowrap;

            .ant-table-header {
              flex: none;
            }

            .ant-table-body {
              flex: auto;
              overflow: scroll;
              display: flex !important;
              flex-direction: column;
            }
          }
        }

        .ant-table-pagination {
          flex: none;
        }
      }
    }
  }

  &.no-data {
    .ant-spin-nested-loading {
      .ant-spin-container {
        .ant-table {
          .ant-table-container {
            .ant-table-body {
              flex: auto;

              > table {
                height: 100%;

                .ant-table-placeholder {
                  .ant-empty {
                    color: $color-grey-13;

                    .ant-empty-image {
                      height: rem(24);
                    }

                    .ant-empty-description {
                      color: $color-grey-13;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

// Select Component Overrides
.ant-select.design-v1 {
  @extend .table-typography;
  color: $color-regent-grey;

  .ant-select-selector {
    line-height: rem(16);
    border-radius: rem(8);
    border: 1px solid $color-grey-13;
    height: rem(28);
    background-color: transparent;

    .ant-select-selection-overflow {
      .ant-select-selection-overflow-item {
        pointer-events: none;

        .ant-select-selection-item {
          background: transparent;
          border: none;
          .ant-checkbox {
            display: none;
            + span {
              padding: 0;
            }
          }
          .ant-select-selection-item-content {
            @extend .table-typography;
            color: var(--text-color);
          }

          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
    }
  }

  &.ant-select-disabled:where(
      .css-dev-only-do-not-override-1pgu1k1
    ).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: transparent;
  }

  &:where(.css-dev-only-do-not-override-1pgu1k1).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid $color-grey-13;
  }

  &:where(.css-dev-only-do-not-override-1pgu1k1).ant-select-single:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    height: rem(28);
  }

  .ant-select-arrow {
    color: $color-regent-grey;
  }
}

// Select Component Overrides
.ant-select.design-v2 {
  @extend .table-typography;
  color: $color-regent-grey;

  .ant-select-selector {
    line-height: rem(16);
    border-radius: rem(8);
    border: 1px solid $color-grey-13;
    height: rem(28);
    background-color: transparent;

    .ant-select-selection-overflow {
      position: static;

      .ant-select-selection-overflow-item {
        pointer-events: none;

        .ant-select-selection-item {
          background: transparent;
          border: none;
          .ant-checkbox {
            display: none;
            + span {
              padding: 0;
            }
          }
          .ant-select-selection-item-content {
            @extend .table-typography;
            color: var(--text-color);
          }

          .ant-select-selection-item-remove {
            display: none;
          }
        }
      }
    }

    .ant-select-selection-search {
      .ant-select-selection-search-input {
        height: 100%;
      }
    }
  }

  &.input-small {
    height: rem(28);
    .ant-select-selector {
      height: rem(28);
    }
  }

  &.input-medium {
    height: rem(38);
    .ant-select-selector {
      height: rem(38);
      align-items: center;

      .ant-select-selection-item {
        @extend .table-typography;
        line-height: rem(38);
        height: inherit;
      }

      .ant-select-selection-placeholder {
        @extend .table-typography;
        line-height: rem(38);
      }
    }
  }

  &.ant-select-disabled:where(
      .css-dev-only-do-not-override-1pgu1k1
    ).ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: transparent;
  }

  &:where(.css-dev-only-do-not-override-1pgu1k1).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    border: 1px solid $color-grey-13;
  }

  .ant-select-arrow {
    color: $color-regent-grey;
  }
}

// Date Picker Overrides
.ant-picker.design-v1 {
  @extend .table-typography;
  line-height: rem(16);
  border-radius: rem(8);
  border: 1px solid $color-grey-13;
  height: rem(28);
  background-color: transparent;

  &.ant-picker-focused {
    box-shadow: none;
  }
}

// Date Picker Overrides
.ant-picker.design-v2 {
  @extend .table-typography;
  line-height: rem(16);
  border-radius: rem(8);
  border: 1px solid $color-grey-13;
  height: rem(28);
  background-color: transparent;

  &.ant-picker-focused {
    box-shadow: none;
  }
}

.ant-picker-dropdown {
  .ant-picker-panels {
    margin-top: 0;
  }

  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start::before,
  .ant-picker-panel
    > :not(.ant-picker-date-panel)
    .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end::before,
  .ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: var(--primary-bg-color);
  }
  .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover::before {
    background: var(--primary-bg-color);
  }

  .ant-picker-header-view > button {
    &:hover {
      color: var(--primary-color);
    }
  }

  .ant-picker-time-panel-column {
    > li.ant-picker-time-panel-cell-selected {
      .ant-picker-time-panel-cell-inner {
        background: var(--button-icon-color);
        color: $color-white;
      }
    }
  }

  .ant-picker-panel .ant-picker-footer {
    border-top: none;

    .ant-picker-ranges {
      .ant-picker-ok {
        display: none;
      }
    }
  }
}

// Select dropdown overrides
.ant-select-dropdown {
  border-radius: 10px;
  border: 1px solid $color-grey-1;
  color: currentColor;
}

// Select dropdown modal overrides
.rc-virtual-list {
  @extend .table-typography;

  .rc-virtual-list-holder {
    @extend .new-styled-scroll;

    &::-webkit-scrollbar {
      width: 5px;
    }

    .rc-virtual-list-holder-inner {
      .ant-chckbox-wrapper-inner,
      .ant-checkbox-wrapper {
        color: currentColor;
        white-space: normal;

        .ant-checkbox-checked,
        .ant-checkbox-checked:hover {
          .ant-checkbox-inner,
          .ant-checkbox-inner:hover {
            background-color: var(--button-icon-color);
            border-color: var(--button-icon-color);
            border-radius: rem(4);
          }
        }

        .ant-checkbox-checked:after {
          border-color: var(--button-icon-color);
        }
      }

      .ant-select-item-option-state {
        display: none;
      }

      .ant-select-item-group,
      .ant-select-item-option-grouped {
        min-height: rem(16);
        padding-top: rem(2);
        padding-bottom: rem(2);
      }
      .ant-select-item-option-content {
        min-height: rem(16);
      }
    }

    .ant-select-item-option {
      background-color: $color-white;
      color: currentColor;

      &.ant-select-item-option-selected {
        color: var(--primary-color);
      }

      &.ant-select-item-option-disabled {
        opacity: 0.5;
      }

      &:hover {
        color: var(--primary-color);
      }
    }

    .ant-select-item-option-content {
      white-space: normal;
      word-break: break-all;
    }
  }
}

// Form Component Overried
.ant-form {
  .ant-row {
    .ant-form-item-label {
      padding: 0;
      label {
        @extend .font-caption-bold;
        padding-bottom: rem(8);
        height: 0;
      }
    }
    .ant-form-item-control {
      .ant-form-item-control-input {
        .ant-form-item-control-input-content > input,
        .ant-form-item-control-input-content > .ant-input {
          background-color: $color-grey-0;
          border-radius: rem(10);
        }
      }
    }
  }
}

.ant-modal-root {
  .ant-modal-mask {
    background: rgba(10, 30, 64, 0.8);
    backdrop-filter: blur(2px);
  }

  .ant-modal-content {
    border-radius: rem(12);
    z-index: 3;
    color: currentColor;

    .ant-modal-header {
      color: currentColor;
      border-radius: rem(12);
      border-bottom: none;

      .ant-modal-title {
        color: currentColor;
        @extend .modal-heading;
      }
    }
  }
}

//antd switch overrides

.ant-switch {
  min-height: rem(12);
  min-width: rem(40);

  &.ant-switch-checked {
    background-color: var(--button-icon-color);
  }

  .ant-switch-handle {
    min-height: rem(8);
    min-width: rem(8);
  }
  &.ant-switch-small {
    min-width: rem(30);
  }
}

.ant-input-group {
  color: currentColor;
}

//antd radio group overrides
.ant-radio-group:not(.no-custom-style) {
  display: flex;
  gap: rem(10);

  .ant-radio-button-wrapper {
    z-index: auto;
    border-radius: rem(10);
    background-color: $color-grey-0;
    border: 2px solid $color-grey-1;
    &::before {
      display: none;
    }
    &:hover {
      color: $color-white;
      background-color: var(--button-icon-color);
      border: 2px solid var(--button-icon-color);
    }
    &.ant-radio-button-wrapper-checked {
      background-color: $color-grey-0;
      border: 2px solid var(--button-icon-color) !important;
      color: $color-pure-black;
      &:hover {
        background-color: var(--button-icon-color);
        border: 2px solid var(--button-icon-color);
      }
    }
  }

  .ant-radio-wrapper {
    .ant-radio-checked {
      .ant-radio-inner,
      &.ant-radio-checked::after {
        border-color: var(--button-icon-color);
        background-color: var(--button-icon-color);
      }
    }

    .ant-radio-inner::after {
      background-color: $color-white;
    }

    &.ant-radio-wrapper:hover .ant-radio,
    .ant-radio:hover .ant-radio-inner,
    .ant-radio-input:focus + .ant-radio-inner {
      border-color: var(--button-icon-color);
    }
  }
}

// Ant Cascader overrides
.ant-cascader {
  width: 100%;

  &.design-version-2 {
    .ant-select-selector {
      height: rem(38);
      border-radius: rem(8);
      border: 1px solid $color-grey-13;

      .ant-select-selection-item {
        line-height: rem(35);
      }
    }

    .ant-select-arrow {
      color: $color-regent-grey;
    }
  }
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: transparent;
}

// Ant Cascader dropdown overrides
.ant-cascader-dropdown {
  .ant-cascader-menu {
    flex: 1;
  }

  .ant-cascader-menu-item {
    color: currentColor;
    &.ant-cascader-menu-item-active {
      background-color: $color-white;
      color: var(--button-icon-color);
    }
  }
}

// Ant date picker overrides
.ant-picker-date-panel {
  .ant-picker-cell-in-range,
  .ant-picker-cell-range-start,
  .ant-picker-cell-range-end,
  .ant-picker-cell-selected {
    .ant-picker-cell-inner {
      background-color: var(--button-icon-color) !important;
      color: $color-white;
    }
  }

  .ant-picker-cell-in-view.ant-picker-cell-in-range::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-end:not(
      .ant-picker-cell-range-end-single
    )::before,
  .ant-picker-cell-in-view.ant-picker-cell-range-start:not(
      .ant-picker-cell-range-start-single
    )::before {
    background-color: transparent;
  }
}

// Removes dates of months that don't belong to the current opened month in the calendar
.ant-picker-date-panel
  .ant-picker-cell:not(.ant-picker-cell-in-view)
  .ant-picker-cell-inner {
  display: none;
}

.ant-picker-date-panel .ant-picker-cell:not(.ant-picker-cell-in-view) {
  pointer-events: none;
}

//Checkbox overrides
.ant-checkbox-wrapper {
  color: currentColor;

  .ant-checkbox-checked {
    .ant-checkbox-inner {
      border-color: var(--button-icon-color);
      background-color: var(--button-icon-color);
    }
  }
  .ant-checkbox-indeterminate {
    .ant-checkbox-inner::after {
      display: none;
    }
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover
    .ant-checkbox-inner,
  .ant-checkbox:hover .ant-checkbox-inner,
  .ant-checkbox-checked::after {
    border-color: var(--button-icon-color);
  }

  &.ant-checkbox-wrapper:not(.ant-checkbox-wrapper-disabled):hover {
    color: var(--button-icon-color);
    .ant-checkbox-checked:not(.ant-checkbox-disabled) {
      .ant-checkbox-inner {
        background-color: var(--button-icon-color);
      }
      &:after {
        border-color: var(--button-icon-color);
      }
    }
  }

  &.ant-checkbox-wrapper + .ant-checkbox-wrapper {
    margin-left: 0;
  }
}

// Table loading animating icon
.ant-spin-dot-item {
  background-color: var(--primary-color);
}

// Antd Carousel arrow settings
.ant-carousel .slick-next {
  right: rem(1);
  padding-left: rem(40);
  padding-right: rem(8);
  background: $transparent-white-gradient;
}
.ant-carousel .slick-prev {
  left: rem(1);
  padding-right: rem(40);
  padding-left: rem(8);
  background: $white-transparent-gradient;
}

.ant-carousel {
  color: currentColor;

  .slick-next,
  .slick-prev {
    color: $color-primary-button;
    z-index: 1;
    top: 0;
    width: rem(70);
    height: 100%;

    &.slick-disabled {
      display: none !important;
    }
  }
}

// Properties for items inside Antd
.slick-slide {
  margin-right: rem(24);
  pointer-events: all !important;
}
.slick-track {
  display: flex !important;
}

// Table sorting icon color and padding
.ant-table-column-sorter {
  padding-top: 0;
  height: rem(11);
}
.ant-table-column-sorter-down.active {
  color: $color-pure-black;
}

// Tooltip overrides
.ant-tooltip {
  .ant-tooltip-inner {
    background-color: $color-white;
    color: var(--text-color);
    border-radius: rem(8);
  }
  .ant-tooltip-arrow::before {
    background: $color-white;
  }
}

//Divider overrides
.ant-divider {
  &.ant-divider-horizontal {
    border-top: rem(1) solid $color-grey-1;
  }

  &.ant-divider-with-text-center {
    border-top: none;
    border-color: $color-grey-1;
  }
}

// For error tooltip styling.
.tooltip-error-body {
  .ant-tooltip-content {
    .ant-tooltip-arrow-content {
      background-color: $color-error-bg;
      border: rem(1.5) $color-error-border solid;
      --antd-arrow-background-color: $color-error-bg;
    }
    .ant-tooltip-inner {
      background-color: $color-error-bg;
      border: rem(1.5) $color-error-border solid;
      color: $color-error-message;
      text-align: center;
    }
  }
}

// Collapse Accordion overrides
.ant-collapse {
  width: 50%;
  gap: rem(32);
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    border: 0.5px solid $color-grey-1;
    border-radius: rem(12) !important;
    background-color: $color-grey-0;

    &.ant-collapse-item-active {
      border: 2px solid var(--primary-color);
    }

    .ant-collapse-header {
      color: var(--text-color);
      .ant-collapse-arrow {
        color: $color-grey-5;
        font-size: rem(24);
      }
    }

    .panel-heading {
      @extend .form-header;
      gap: rem(9);
    }

    .ant-collapse-content {
      color: var(--text-color);
      margin: 0 1rem;
      border-top: 0.5px solid $color-grey-1;
      background-color: $color-grey-0;
      border-bottom-left-radius: rem(12);
      border-bottom-right-radius: rem(12);

      .ant-collapse-content-box {
        padding: 1rem 0;
      }
    }

    .collapse-body {
      gap: rem(24);
      position: relative;
    }
  }
}

// Accordion Design V2
.ant-collapse.design-v2 {
  border: none;
  background-color: transparent;

  .ant-collapse-item {
    border: 1px solid $color-grey-13;
    border-radius: rem(12) !important;
    background-color: transparent;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);

    &.ant-collapse-item-active {
      .ant-collapse-header {
        background: $color-grey-12;
        border-radius: rem(12) rem(12) 0 0;
        border-bottom: 1px solid $color-grey-13;
      }
    }

    .ant-collapse-header {
      background: transparent;
      border-radius: rem(12);
      padding: rem(12) rem(16);
      border-bottom: 1px solid $color-grey-13;
      display: flex;
      align-items: center;

      .ant-collapse-arrow {
        color: $color-regent-grey;
        font-size: rem(24);
      }
    }

    .ant-collapse-content {
      margin: 0 1rem;
      border-top: none;
      background-color: transparent;

      .ant-collapse-content-box {
        padding: 1rem 0;
      }
    }

    .collapse-body {
      gap: rem(24);
      position: relative;
    }
  }
}

// Ant Tabs Overrides
.ant-tabs {
  &.ant-tabs-card {
    .ant-tabs-nav {
      margin: 0;

      &::before {
        border-color: $color-grey-13;
      }

      .ant-tabs-nav-list {
        .ant-tabs-tab {
          border-color: $color-grey-13;
          background-color: $color-grey-12;

          @extend .font-button;

          &:hover .ant-tabs-tab-btn {
            color: var(--primary-color);
          }

          &.ant-tabs-tab-active {
            border-bottom-color: transparent;
            background-color: $color-white;

            .ant-tabs-tab-btn {
              color: var(--primary-color);
            }
          }
        }
      }
    }

    .ant-tabs-content-holder {
      border: 1px solid $color-grey-13;
      border-top: none;
      border-radius: 0px 0 rem(12) rem(12);
      padding: rem(24) rem(16) rem(16);
    }
  }
}

// Graph Overrides
.g2-html-annotation {
  z-index: 1 !important;
}

// Steps Overrides
.ant-steps {
  &-item {
    &-container {
      .ant-steps-item-content {
        .ant-steps-item-title {
          color: $color-secondary-black !important;
          font-weight: 600;
        }
        .ant-steps-item-description {
          color: $color-grey-9 !important;
        }
      }
    }
    &.ant-steps-item-active.ant-steps-item-process {
      .ant-steps-item-icon {
        background: $color-primary-button;
      }
    }
    &.ant-steps-item-wait {
      .ant-steps-item-icon {
        background: $color-grey-12;
        border: 1px solid $color-scroll-bar;

        .ant-steps-icon {
          color: $color-grey-9;
        }
      }
    }

    &:not(.ant-steps-item-finish) {
      .ant-steps-item-tail::after {
        background: $color-scroll-bar !important;
        width: 1.2px !important;
        transform: scale(1.1);
      }
    }

    &.ant-steps-item-finish {
      .ant-steps-item-tail::after {
        background: $color-primary-button !important;
      }
      .ant-steps-item-icon {
        background: $color-primary-button;
        transform: scale(0.8);
        .ant-steps-icon {
          color: $color-white;
        }
      }
    }
  }
}

// Ant upload dragger overrides
.ant-upload-wrapper {
  .ant-upload-drag {
    border: 1px dashed $color-grey-13;
    border-radius: 10px;
    background: transparent;

    &-container {
      margin: rem(24);
      color: $color-grey-9;
    }
  }
}
