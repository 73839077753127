@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

@keyframes chainReaction {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes blinkMove {
  50% {
    transform: scale(2);
    opacity: 0;
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}

@mixin parent-menu-container-styles {
  border-radius: rem(12);
  margin: 0 rem(16);

  .parent-menu-icon {
    height: rem(48);
    width: rem(48);
    border-radius: rem(12);
    color: $color-grey-9;
    transition: all 300ms;

    > span {
      height: rem(24);
      width: rem(24);

      .menu-icon {
        line-height: initial;
      }
    }
  }

  .blink-circle,
  .blink-circle::before {
    content: ' ';
    width: rem(10);
    height: rem(10);
    border-radius: 50%;
    margin: 0 auto;
    transition: all 0.3s;
    background-color: $color-primary-green;
    display: flex;
  }

  .blink-circle::before {
    animation: blinkMove 2s infinite;
    background-color: $color-primary-green;
  }

  .menu-title {
    color: $color-grey-9;
    animation: chainReaction 2s;
  }

  &.selected-menu {
    background: $green-gradient-selection-secondary;
    color: $color-primary-button;

    .parent-menu-icon {
      color: inherit;
      height: rem(48);
      width: rem(48);
    }

    .menu-title {
      color: inherit;
    }

    &.with-sub-menu {
      border-radius: rem(12) 0 0 rem(12);
      margin-right: 0;
      position: relative;

      &::before {
        content: '';
        position: absolute;
        top: 48px;
        height: 50px;
        left: 179px;
        width: 25px;
        border-top-right-radius: 12px;
        box-shadow: 0 -25px 0 0 #ecf2e9;
        z-index: -1;
      }

      &::after {
        content: '';
        position: absolute;
        top: -50px;
        height: 50px;
        left: 179px;
        width: 25px;
        border-bottom-right-radius: 12px;
        box-shadow: 0 25px 0 0 #ecf2e9;
        z-index: -1;
      }
    }
  }
}

.navigation-menu {
  z-index: 3;
  margin: rem(8) 0;
  overflow-y: auto;
  height: calc(100% - 10em);

  .parent-navigation-menu {
    margin: 0 auto;

    .parent-menu-list {
      padding-top: rem(12);

      .non-feature-menu {
        padding-top: rem(4);
        border-top: 1px solid $color-grey-10;
      }

      .parent-menu-container {
        @include parent-menu-container-styles;
      }
    }

    .custom-options {
      position: sticky;
      bottom: 0;
      background-color: $color-off-white;
      margin: rem(16) rem(16) 0 rem(16);

      .profile-image {
        height: rem(48);
        width: rem(48);
        border-radius: rem(12);
      }

      .user-banner {
        padding: rem(8);
        border-radius: rem(12);
        background-color: $color-grey-11;

        .profile-image {
          height: rem(32);
          width: rem(32);
          border-radius: 50%;
        }

        .user-name {
          white-space: nowrap;
          text-overflow: ellipsis;
          width: rem(108);
          overflow: auto;
        }
      }
    }
  }
}

.logout-tooltip {
  min-width: rem(227);
}
