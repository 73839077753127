@import 'styles/global';
@import 'styles/functions';
@import 'styles/colors';

.savings-track {
  .graph {
    overflow: scroll !important;
    @extend .hide-scroll;

    .cost-comparison-card {
      height: auto;
      margin: 0;
      padding: rem(12);
      border-left: 1px solid $color-grey-13;
    }
  }
}
