@import 'styles/functions';
@import 'styles/colors';

.report-chart-table-header {
  margin-bottom: rem(8);

  .edit-pencil {
    color: $color-regent-grey;
  }
}
