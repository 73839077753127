@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';
@import 'styles/flex';

.dashboard-header {
  border-bottom: 1px solid $color-grey-10;
  padding: 0 rem(24);
  min-height: rem(80);

  .header {
    background-color: $color-white;

    &-right {
      gap: 1.5rem;

      .ant-avatar-group {
        .ant-avatar:not(:first-child) {
          margin-left: rem(-12);
        }
        .ant-avatar {
          border: none;
          @extend .flex;
          @extend .flex-center;

          .ant-avatar-string {
            @extend .font-caption-bold;
          }
        }
      }
    }
    .table-view-title {
      margin-right: rem(12);
    }
  }
}
