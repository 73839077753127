.grid {
  display: grid;

  &-colum-1 {
    grid-column: 1;
  }

  &-colum-2 {
    grid-column: 2;
  }

  &-row-1 {
    grid-row: 1;
  }

  &-row-2 {
    grid-row: 2;
  }
}
