@import 'styles/colors';

.quickAction-overlay {
  min-width: auto !important;

  ul {
    list-style: none;
    background-color: $color-white;
    border-radius: 10px;
    margin: 0;
    padding: 1rem;
    width: max-content;
    li {
      padding: 4px;

      &.ant-dropdown-menu-item-danger {
        .ant-dropdown-menu-title-content {
          color: $color-delete-button;
        }

        &:not(.ant-dropdown-menu-item-disabled):hover {
          background-color: $color-delete-button !important;
          .ant-dropdown-menu-title-content {
            color: $color-white;
          }
        }
      }
    }
  }
}

.quick-action-disabled-icon {
  color: $color-grey-4;
  cursor: not-allowed !important;
}
