@import 'styles/functions';
@import 'styles/colors';

.waste-management-scorecard-dashboard {
  #graph-container {
    border: 0.5px solid $color-grey-13;
    border-radius: rem(12);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    transition: 0.3s;
  }

  .graph-card {
    height: rem(410);
    overflow: hidden;
    padding: 1rem;

    .filters {
      display: flex;
      gap: rem(16);
      margin-top: rem(8);

      .ant-select {
        width: 15%;
      }
    }

    .graph {
      height: rem(330);
      margin-top: rem(16);
    }
  }
}
