@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';
@import 'styles/mixins';

.cost-optimization-insights {
  .cost-optimization-insights-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .status-summary-container {
    .summary-table {
      &.selected {
        border: rem(2) solid var(--primary-color);
      }
    }

    .graph-area {
      border-radius: rem(12);
      border: rem(0.5) solid $color-grey-1;
      padding: 1rem;
      height: rem(300);
      min-width: 0;

      .sub-title {
        color: $color-grey-5;
      }

      .graph {
        height: 100%;
        overflow: hidden;

        &.with-sub-title {
          height: 100%;
        }

        &.resource-data {
          overflow-y: auto;
          .value {
            word-break: break-word;
          }
        }
      }

      .no-chart-selection {
        .heading,
        .sub-heading {
          color: $color-grey-5;
        }
      }

      .previous-icon {
        color: var(--button-icon-color);
        height: rem(16);
        width: rem(16);
      }
    }
  }
}

.rec-table-container {
  padding: 1rem;
  border: 0.0625rem solid $color-grey-13;
  border-radius: rem(12);

  .graph-header {
    padding-left: 0;
  }

  .snow-status-tabs {
    margin: 1rem 0;

    :first-child {
      border-top-left-radius: rem(12);
      border-bottom-left-radius: rem(12);
    }

    :last-child {
      border-top-right-radius: rem(12);
      border-bottom-right-radius: rem(12);
    }

    .ant-radio-button-wrapper-checked {
      color: $color-primary-button;
      border-color: $color-primary-button;
    }
  }

  .recommendations-table {
    height: calc(100vh - 22em);

    &.with-filters {
      height: calc(100vh - 26em);
    }
    .ant-table-thead {
      .ant-table-column-sorters {
        align-items: flex-start;
        .ant-table-column-sorter-up,
        .ant-table-column-sorter-down {
          font-size: rem(10);
        }
      }

      .ant-table-cell-fix-left {
        z-index: 2 !important;
      }
    }

    .ant-table-body {
      .ant-table-tbody {
        .ant-table-cell {
          @include status-text;
        }
      }
    }

    .ant-table-summary {
      .loader-row {
        .ant-table-cell {
          padding: 0;
          height: rem(16);
        }
      }
    }
  }

  .table-heading-dropdown {
    width: rem(250);
  }

  .create-ticket-cta {
    &.ant-btn {
      &.ant-btn-primary {
        margin-left: rem(8);

        &.error {
          background-color: $color-grey-1 !important;
          color: $color-error-message !important;
        }
      }
    }
  }

  .delete-view-button {
    flex-direction: row-reverse;
    &.ant-btn {
      &.ant-btn-primary {
        margin-left: rem(8);
        background-color: $color-delete-button !important;
      }
    }
  }

  .view-dropdown {
    width: rem(200);
  }
}

.expand-modal {
  .rec-table-container {
    border: none;
    .recommendations-table {
      height: calc(100vh - 12em);
    }

    &.with-filter {
      .recommendations-table {
        height: calc(100vh - 17em);
      }
    }
  }

  .cost-savings-table {
    height: calc(100vh - 11em);
  }
}

.filter-view-dropdown {
  .dropdown-container {
    max-height: rem(160);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .view-type {
    padding: rem(16) rem(8);
  }
  .ruleset-options {
    padding: rem(5) rem(12);
  }
}
