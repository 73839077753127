@import 'styles/functions';
@import 'styles/colors';

.tag-mapping {
  .tag-mapping-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .table-container {
    height: calc(100vh - 13em);

    .table-section {
      .tag-name {
        .arrow {
          color: $color-regent-grey;
        }
      }

      .provider-icon {
        height: rem(18);
        width: rem(18);
      }

      .vertical-divider {
        border-color: $color-scroll-bar;
      }

      .field-name {
        background: $green-gradient-selection-secondary;
      }

      .ant-table-thead {
        td.ant-table-row-expand-icon-cell::after {
          content: '#';
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      .ant-table-body {
        td.ant-table-row-expand-icon-cell {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
