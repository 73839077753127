@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.navigation-path {
  height: rem($navigation-path-height);
  padding: 0 rem(24);
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  color: $color-grey-9;
  position: sticky;
  top: rem($page-header-height);
  z-index: 2;
}
