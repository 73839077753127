@import 'styles/functions';
@import 'styles/colors';

.success-component {
  .dashboard-button {
    margin-top: rem(16);
  }

  .sub-title {
    margin: rem(10);
    max-width: rem(585);
    text-align: center;
    color: $color-grey-9;
  }
  .additional-component {
    margin-top: rem(16);
  }
}
