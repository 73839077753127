@import 'styles/functions';
@import 'styles/colors';
@import 'styles/variables';

.release-notes {
  .release-notes-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .release-notes-list {
    padding: rem(24);

    .ant-collapse-item {
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.04) !important;
    }

    .release-note-heading {
      .release-date {
        color: $color-grey-5;
      }
    }

    .release-description {
      .status {
        width: fit-content;
        background: color-mix(in srgb, currentColor 30%, transparent);

        &.new {
          color: $color-vilolet-blue;
        }

        &.improvements {
          color: $color-gold-foil;
        }

        &.bug-fixes {
          color: $color-slate-grey;
        }
      }
    }

    .feature-list {
      margin-top: rem(12);

      .feature-description {
        display: list-item;
        list-style-type: disc;
        margin-left: rem(16);
      }
    }
  }
}
