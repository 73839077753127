@import 'styles/colors';
@import 'styles/functions';
@import 'styles/mixins';
@import 'styles/global';
@import 'styles/fonts';

.usage-metering {
  #graph-container {
    border: 0.5px solid $color-grey-13;
    border-radius: rem(12);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
    transition: 0.3s;
    min-height: rem(300);
    padding: rem(16);
  }

  .graph {
    height: rem(280);

    .graph-area {
      height: inherit;
    }
  }
}

.expand-modal {
  .graph {
    .tabular-view {
      width: 100%;
    }
  }

  .cluster-breakdown,
  .effective-cpu-utiilization,
  .namespace-usage-cost,
  .pod-usage-cost {
    .graph {
      height: 100% !important;
      margin-top: 0 !important;

      .tabular-view {
        padding: 0 !important;
      }
    }
  }
}
