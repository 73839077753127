@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.tag-content {
  width: rem(250);
  padding-left: rem(12);
  color: $color-secondary-black;
}
.tag-value-count {
  padding: auto 0;
  .count {
    padding: rem(4);
    border-radius: 50%;
    background-color: $color-primary-green;
    min-width: rem(16);
    width: fit-content;
    height: rem(16);
    color: $color-white;
  }
}

.tag-suffix-icon {
  margin-right: rem(5);
  color: $color-grey-5;
}
.ant-select-selection-overflow-item {
  &.ant-select-selection-overflow-item-suffix {
    display: none;
  }
}

.tag-dropdown {
  &.ant-select-dropdown {
    .tag-dropdown-wrapper {
      padding: rem(12);

      .ant-select-item {
        padding-left: 0;
      }

      .ant-checkbox-wrapper-disabled {
        .ant-checkbox-inner {
          border-color: $color-grey-0;
          background-color: $color-grey-0;
        }

        .label {
          color: $color-secondary-black;
        }
      }
    }
  }
}
