@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.tco-projects {
  .tco-projects-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .tco-projects-content {
    height: calc(100vh - 15em);

    .tco-projects-table {
      margin-top: rem(16);

      .name-list {
        background-color: $color-grey-1;
        border-radius: rem(8);
        padding: rem(4) rem(8) rem(6);
        margin: rem(2);
      }
    }
  }

  .page-footer {
    padding: 0 rem(24) rem(16);
  }
}
