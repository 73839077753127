@import 'styles/functions';
@import 'styles/colors';

.chat-bot-llms-modal {
  .ant-modal-body {
    margin-top: rem(16);

    .llm-models-selection {
      border: 1px solid $color-grey-13;
      border-radius: rem(10);

      .heading {
        padding: rem(8);
        background-color: $color-grey-12;
        border-radius: rem(10) rem(10) 0 0;
      }

      .llm-models {
        padding: rem(8);

        .model-name {
          font-weight: 600;
        }

        .llm-sub-models {
          margin-left: rem(16);
        }
      }
    }
  }
}
