@import 'styles/functions';
@import 'styles/colors';

.g2-tooltip:has(.custom-tooltip-content) {
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08) !important;
  border-radius: rem(8) !important;
  opacity: 1 !important;
}

.custom-tooltip-content {
  min-width: rem(200);
  margin: rem(8) 0;

  .color-disc {
    width: rem(8);
    height: rem(8);
    border-radius: rem(4);
  }

  .y-title {
    color: $color-secondary-black;
  }

  .value {
    color: $color-secondary-black;
  }
}
