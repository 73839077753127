@import 'styles/functions';
@import 'styles/colors';

.report-options {
  padding-top: rem(16);
  height: calc(100vh - 10em);
  overflow: auto;

  .options-divider {
    border-color: $color-scroll-bar;
  }
}
