@import 'styles/colors';
@import 'styles/functions';

.recommendation-table-with-workflow {
  .snow-status-tabs {
    margin: rem(8) 0 0 0;

    :first-child {
      border-top-left-radius: rem(12);
      border-bottom-left-radius: rem(12);
    }

    :last-child {
      border-top-right-radius: rem(12);
      border-bottom-right-radius: rem(12);
    }

    .ant-radio-button-wrapper-checked {
      color: $color-primary-button;
      border-color: $color-primary-button;
    }
  }
}
