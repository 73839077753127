@import 'styles/functions';
@import 'styles/colors';

.connection-title {
  padding: rem(5) 0;
  margin-left: rem(15);

  .sub-title {
    opacity: 0.7;
  }
}

.connection-list {
  width: rem(242);
}
