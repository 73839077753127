@import 'styles/colors';
@import 'styles/functions';

.tags-list {
  .tag-item {
    background-color: $color-grey-1;
    border-radius: rem(8);
    padding: rem(6) rem(8);
    margin: rem(2);
    width: fit-content;

    &.link {
      color: $color-highlighter-purple;
    }
  }
}
