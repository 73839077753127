@import 'styles/functions';

.custom-color-picker {
  width: rem(264);
  padding: 0 !important;
  border-radius: 0 !important;
  box-shadow: none !important;

  div {
    border-radius: rem(4);

    input {
      border-radius: rem(4);
    }
  }

  & > div:nth-child(2) > div:nth-child(2) {
    display: none;
  }
}
