@import 'styles/functions';
@import 'styles/colors';

.selected-connection-tag-dropdown {
  .connection-tag-collapse {
    gap: rem(16);

    .connection-tag-panel {
      background-color: transparent;
      border: none;

      &.ant-collapse-item-active {
        border: none;
      }

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          padding-right: rem(6);

          .collapse-arrow {
            color: $color-grey-9;
          }
        }

        .values-count {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }

      .ant-collapse-content {
        background-color: inherit;
        border: none;
        margin: 0 0 0 rem(30);

        .ant-collapse-content-box {
          padding: rem(8) 0 0;

          ul {
            &.draggable-tags-list {
              margin: 0;
              padding: 0;
              list-style-type: none;
            }
          }
        }
      }
    }
  }
}
