@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.cost-allocation-dashboard {
  .cost-allocation-dashboard-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(14) rem(24);
  }

  .cost-allocation-dashboard-content {
    .dashboard-area {
      margin: rem(24);

      #graph-container {
        border: 0.5px solid $color-grey-13;
        border-radius: rem(12);
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
        transition: 0.3s;
        &.selected {
          border: rem(2) solid var(--primary-color);
          flex: 1.5;
        }
      }
      .graph-card {
        height: rem(336);
        padding: rem(16);
        display: flex;
        flex-direction: column;
        flex: 1;

        .filters {
          display: flex;
          gap: rem(16);
          margin-top: rem(8);

          .ant-select {
            width: 30%;
          }
        }

        .graph {
          height: 100%;
          overflow: hidden;
          margin-top: rem(16);

          &.no-filters {
            height: rem(270);
          }
        }
      }
    }
  }
}
