@import 'styles/functions';
@import 'styles/colors';

.report-table-or-chart {
  border: 0.5px solid $color-grey-13;
  border-radius: rem(12);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);
  min-height: rem(350);
  height: calc(100% - 4em);
  margin: rem(24);
  padding: rem(16);

  .no-data {
    color: $color-grey-13;
  }
}
