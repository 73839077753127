@import 'styles/colors';

.savings-effectiveness {
  &.graph-card.tabular-view {
    height: 100% !important;

    .graph {
      height: 100% !important;
    }
  }
}
