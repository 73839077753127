@import 'styles/functions';

.confirm-modal {
  margin-top: rem(15);
  text-align: center;
  margin-bottom: rem(10);

  .body {
    margin-top: rem(5);
  }

  .button {
    margin-top: rem(20);
    gap: rem(10);
    button {
      width: rem(126);
    }
  }
}
