@import 'styles/functions';
@import 'styles/variables';
@import 'styles/colors';

.granulate-add-connection {
  height: calc(100% - 7em);
  .add-connection-progress-bar {
    height: calc(
      100vh - rem($navigation-path-height) - rem($page-header-height)
    );
    overflow: auto;
  }

  .form-section {
    height: calc(
      100vh - rem($navigation-path-height) - rem($page-header-height)
    );
    overflow: auto;

    .connection-form-section {
      padding: rem(10) rem(40);

      .form-item {
        &.disable {
          pointer-events: none;
          filter: grayscale(1);
        }
      }
    }
  }
}

.ant-select-dropdown {
  .region-cluster {
    padding: rem(8);
    height: rem(180);

    .region {
      max-height: rem(180);
      overflow-y: auto;
      border-right: rem(1) solid $color-grey-13;
    }
    .cluster {
      max-height: rem(180);
      overflow-y: auto;
      margin-left: rem(4);
    }
    .cluster-header,
    .region-header {
      color: $color-grey-9;
      text-transform: uppercase;
    }
  }
  .service-dropdown {
    height: rem(170);
    padding: rem(8);
  }
  .validate-btn {
    width: rem(100);
  }
}
