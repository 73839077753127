@import 'styles/colors';
@import 'styles/functions';

.cost-allocation-horizontal-nav {
  background-color: $color-grey-12;
  padding: rem(6) rem(24);
  border-bottom: $color-grey-10 1px solid;

  .nav-menu {
    background-color: inherit;
    overflow: auto;

    &.ant-menu-vertical {
      border-inline-end: none;
    }

    .ant-menu-item {
      height: rem(28);
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-right: rem(16);
      padding: 0 rem(12);
      width: auto;

      &.ant-menu-item-selected {
        background-color: $color-white;
        color: var(--primary-color);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      }
    }
  }
}
