@import 'styles/functions';
@import 'styles/colors';

.tag-compliance {
  .tag-compliance-content {
    height: calc(100vh - 15em);

    .score-graphs {
      height: rem(300);
      border: 0.5px solid $color-grey-13;
      border-radius: rem(12);
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.02);

      .graph-header-v2 {
        margin: rem(16);
      }

      .charts-container {
        .gauge-chart {
          width: rem(250);
          height: rem(200);
        }
      }
    }
  }
}

.ant-popover.gauge-chart-description {
  margin-top: rem(24);
  .ant-popover-inner-content {
    width: rem(400);
    text-align: center;
  }
}

.ant-modal-body {
  .charts-container {
    height: rem(450);
    .gauge-chart {
      width: rem(250);
      height: rem(200);
    }
  }
}
