@import 'styles/functions';
@import 'styles/colors';

.gprofiler-create-ticket {
  .create-ticket-container {
    margin-top: rem(16);

    .title {
      color: $color-secondary-black;
    }
    .sub-title {
      color: $color-grey-9;
    }
  }
}
