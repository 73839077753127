@import 'styles/colors';
@import 'styles/functions';

.pdf-content-cards,
.pdf-content-cards-insert-last,
.pdf-content-graphs {
  .pdd-single-dropdown,
  .ant-select,
  .ant-picker {
    margin-left: rem(16);
  }
  .extended-filter {
    .ant-picker {
      width: 100%;
    }
  }

  .cost-comparison-card {
    padding-left: rem(12);
    border-left: 1px solid $color-grey-13;
  }

  .cost-optimization-card {
    padding: rem(20);
    border: rem(0.5) solid $color-grey-1;
    border-radius: rem(15);
    .card-heading {
      color: $color-grey-5;
    }
    .each-card {
      width: 20%;
      padding-top: rem(10);
    }
  }

  &.landscape {
    width: rem(950);
    height: rem(521);
    #graph-container {
      height: rem(521);
      background-color: $color-white;
      border-radius: rem(12);
      &.selected {
        border: rem(0.5) solid $color-grey-1;
        flex: 1;
      }
      .graph-header,
      .graph-header-v2 {
        flex-direction: row;
        align-items: center;
        height: rem(48);
        > div {
          width: fit-content;
        }

        .dropdown-checkbox {
          width: 20%;
          .ant-select {
            width: 100%;
          }
        }

        .filters {
          width: 60%;
          .ant-picker {
            flex: 1;
          }
          .ant-select {
            flex: 1;
          }
          .dropdown-checkbox {
            flex: 1;
          }
        }
      }
      .graph {
        height: rem(432);
        &.no-filters {
          height: rem(455);
        }
        .pointer-map {
          .controls {
            display: none;
          }
        }
      }
      .pdf-pie-wrapper {
        width: rem(432);
        height: rem(432);
      }
      .pie-wrapper {
        width: rem(400);
        height: rem(432);
      }
      .legends-wrapper {
        overflow-x: hidden;
        height: rem(432);
      }
    }
  }

  &.potrait {
    height: rem(720);
    width: rem(550);
    #graph-container {
      width: rem(550);
      height: rem(680);
      background-color: $color-white;
      border-radius: rem(12);
      .graph-header,
      .graph-header-v2 {
        flex-direction: row;
        align-items: center;
        > div {
          width: fit-content;
        }
        .dropdown-checkbox {
          width: 20%;
          .ant-select {
            width: 100%;
          }
        }
      }
      .graph {
        width: rem(550);
        height: rem(640);
      }
    }
  }

  .each-card {
    .card {
      margin: rem(4);
    }
    &.remove-flex-fit {
      .card {
        flex: 0;
        min-width: rem(320);
        max-height: rem(110);
      }
    }
  }

  .graph-heading-text {
    white-space: nowrap;
    overflow: hidden;
  }
}
#dashboard-title {
  display: inline-block;
}
