@mixin dropdown-styles {
  .ant-select {
    width: 100%;
    .selected-dropdown-option {
      color: var(--text-color);
      font-weight: 600;
    }
    &-selection-item {
      .selected {
        display: none;
      }
    }

    .ant-select-selection-placeholder {
      display: flex;
      align-items: center;
    }
  }
}

.connection-dropdown {
  @include dropdown-styles;

  .ant-select-selection-item {
    display: flex;
    align-items: flex-end;
  }
}
