@import '../../styles/colors';

.modal {
  &-footer {
    height: 17px;
    margin-top: 17px;
    &-nav {
      gap: 4px;

      &-item {
        width: 54.36px;
        height: 3.05px;
        background-color: $color-primary-bg;
        border-radius: 10px;
      }
      .active {
        background-color: var(--primary-color);
      }
    }
  }
}
