@import 'styles/colors';
@import 'styles/functions';

.dashboard-title {
  gap: 1rem;

  .title-container {
    gap: rem(6);

    .edit {
      gap: rem(6);
    }
  }

  .data-source-type {
    opacity: 0.6;
    text-transform: capitalize;
  }

  .sub-title-container {
    gap: rem(4);
  }
}
