@import 'styles/colors';
@import 'styles/functions';

@mixin dropdown-styles {
  &.ant-select {
    .ant-select-selection-item {
      display: flex;

      .selected-dropdown-option {
        color: currentColor;
        font-weight: 400;
      }
    }
  }
}

.datasource-dropdown {
  @include dropdown-styles;
}

.datasource-dropdown-menu {
  .horizontal-navigation-menu-wrapper {
    border-radius: rem(12);
    border: 1px solid $color-grey-10;
    background: $color-grey-12;
    padding: rem(6);

    .ant-menu {
      flex: 1;
      gap: rem(12);
      &::before,
      &::after {
        display: none;
      }

      .ant-menu-item {
        margin: 0;
        border-radius: rem(8);
        &.menu {
          color: var(--text-color);
        }

        &.active-menu {
          color: var(--button-icon-color);
          background: $color-white;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

          &:hover {
            color: var(--button-icon-color);
          }
        }
      }
    }
  }
}
