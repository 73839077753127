@import 'styles/functions';
@import 'styles/colors';

.instance-wrapper {
  padding: rem(16);
  margin: rem(16);
  border: rem(1) solid $color-grey-13;
  border-radius: rem(12);

  .status-bar {
    margin: rem(16) 0;
    color: $color-secondary-black;

    &-menu {
      margin-right: auto;

      &-item {
        padding: rem(8) rem(12);
        border: rem(1) solid $color-grey-13;
        transition: all 0.3s;

        &:first-child {
          border-top-left-radius: rem(12);
          border-bottom-left-radius: rem(12);
        }

        &:last-child {
          border-top-right-radius: rem(12);
          border-bottom-right-radius: rem(12);
        }

        &.selected {
          color: $color-primary-button;
          border-color: $color-primary-button;
        }
      }
    }
  }
}
