@import 'styles/functions';
@import 'styles/colors';

.tag-compliance {
  .tag-compliance-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;

    .tag-compliance-title {
      .data-source-type {
        opacity: 0.6;
        text-transform: capitalize;
      }
    }
  }
}
