@import 'styles/colors';
@import 'styles/functions';

.application-details {
  .status {
    border-radius: rem(16);
    padding: rem(4) rem(8);
    text-transform: capitalize;
    width: fit-content;
    color: $color-success-message;
    background: $status-active-gradient;
  }

  .ant-table-thead {
    .ant-table-column-has-sorters {
      .ant-table-column-sorters {
        align-items: unset;
      }
    }
  }

  .ant-table-wrapper {
    & .ant-table-tbody > tr.ant-table-row-selected > td {
      background: $color-white;
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }

    &.design-v2
      .ant-table.ant-table-bordered
      .ant-table-container
      .ant-table-body
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: rem(1) solid $color-grey-13;
        }
      }
    }
    &.design-v2
      .ant-table.ant-table-empty
      .ant-table-container
      .ant-table-content
      tbody,
    tfoot {
      > tr {
        &:last-child > td {
          border-bottom: none;
        }
      }
    }
  }
}
