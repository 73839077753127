@import 'styles/colors';
@import 'styles/functions';

.granulate-service-list {
  .search-input {
    width: rem(259);
  }

  .service-list {
    &-item {
      width: rem(412);
    }
    .provider-logo {
      width: rem(35);
      height: rem(35);
    }

    .service-name.selected {
      color: $color-primary-button;
    }

    .connection-name {
      color: $color-grey-5;
    }

    .optimization-potential {
      padding: rem(2) rem(8);
      color: $color-success-message;
      background: $status-active-gradient;
      border-radius: rem(16);
    }

    .create-date {
      color: $color-grey-5;
    }

    .ant-radio-group {
      .ant-space {
        &-item {
          .ant-radio-wrapper {
            .ant-radio {
              transform: translateY(-150%);
            }
          }
        }
      }
    }
  }
}
