@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.coverage-utilization-report {
  &.graph-card {
    height: rem(347);

    .graph {
      height: rem(260);
      margin-top: rem(16);

      .info-icon {
        color: $color-regent-grey;
        height: rem(18);
        width: rem(18);
      }

      .annotation {
        background-color: $color-secondary-black;
        color: $color-white;
        box-shadow: 4px 9px 13px rgba(0, 0, 0, 0.14);
        display: flex;
        gap: rem(8);
        align-items: center;
        border-radius: rem(6);
        padding: rem(6);
        width: max-content;

        .month {
          @extend .font-annotation-bold;
        }

        .value {
          @extend .font-annotation-light;
        }

        &::after {
          content: ' ';
          position: absolute;
          top: 100%; /* At the bottom of the tooltip */
          left: 50%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: $color-secondary-black transparent transparent
            transparent;
        }
      }

      .gauge-container {
        .gauge-graph {
          height: rem(220);
        }
      }
    }

    .savings-types {
      height: rem(28);

      .ant-radio-button-wrapper {
        height: inherit;
        line-height: rem(28);

        .ant-radio-button {
          height: inherit;
        }
      }
    }

    .horizontal-divider {
      color: $color-grey-13;
    }
  }
}

.formulae-tooltip {
  .ant-tooltip-content {
    width: max-content;

    .formulae-divider {
      margin: 0;
    }
  }
}
