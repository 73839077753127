@import 'styles/functions';

.autopilot-savings-dashboard-title {
  .title-container {
    gap: rem(6);
  }

  .data-source-type {
    opacity: 0.6;
    text-transform: capitalize;
  }
}
