@import 'styles/functions';
@import 'styles/colors';

.new-layouts {
  .layout {
    height: rem(18);
    width: rem(38);

    .layout-share {
      background-color: $color-grey-1;
      border-radius: rem(2);
    }

    &:hover {
      .layout-share {
        background-color: $color-primary-green;
      }
    }
  }
}
