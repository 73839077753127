@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';
@import 'styles/variables';

.ticket-integration-container {
  .ticket-integration-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);

    .back-arrow {
      color: var(--button-icon-color);
    }
  }

  .form-section {
    padding-top: 1rem;
  }
}
