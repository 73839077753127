@import 'styles/functions';
@import 'styles/colors';

.report-charts-type-list {
  margin-top: rem(12);

  .chart-type-container {
    width: rem(45);
    height: rem(28);
    border: 1px solid $color-grey-13;
    border-radius: rem(4);

    .chart-icon {
      color: $color-regent-grey;
    }

    &.disabled {
      pointer-events: none;
      filter: grayscale(1);
    }

    &.selected {
      border: 1px solid var(--primary-color);

      .chart-icon {
        color: var(--primary-color);
      }
    }
  }
}
