@import 'styles/colors';
@import 'styles/functions';

.dashboards {
  .title-container {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid rgba(188, 196, 205, 0.4);
  }

  .table-section {
    margin: rem(16) rem(24) rem(24);
    height: calc(100vh - 15em);

    .search-input {
      margin-bottom: rem(16);
    }

    .status {
      &.publish {
        color: $color-success-message;
        background: $status-active-gradient;
      }

      &.deactivated {
        color: $color-status-deactivate;
        background: $status-deactivate-gradient;
      }

      &.draft {
        color: $color-status-installing;
        background: $status-installing-gradient;
      }
    }
  }

  .page-footer {
    margin: 0 rem(24) rem(24);
  }
}
