@import 'styles/functions';
@import 'styles/colors';

.pointer-map {
  position: relative;
  height: inherit;
  overflow-y: hidden;

  .controls {
    position: absolute;
    right: 1rem;
    bottom: 1rem;
    padding: rem(4);
    border: 1px solid $color-grey-13;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.03);
    border-radius: rem(8);
    background-color: $color-white;

    .zoom-button {
      width: rem(18);
      height: rem(18);
      color: $color-grey-9;
    }

    .horizontal-divider {
      width: 100%;
      height: rem(1);
      background-color: $color-grey-13;
      margin: rem(4) rem(8);
    }
  }
}
