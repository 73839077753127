@import 'styles/functions';
@import 'styles/colors';

.report-metric-list {
  margin-top: rem(12);
  .empty-metrics-message {
    color: $color-grey-5;
  }

  ul {
    &.draggable-metric-list {
      margin: 0;
      padding: 0;
      list-style-type: none;

      .metric-item {
        padding-top: rem(12);

        .metric-handle {
          padding-top: rem(2);
          cursor: grab;
        }

        .metric-label {
          overflow-wrap: anywhere;
        }
      }
    }
  }
}
