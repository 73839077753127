@import 'styles/functions';
@import 'styles/colors';

.purchased-rds-ri-card {
  border: rem(1) solid $color-grey-13;
  border-radius: rem(12);
  padding: rem(16);

  .payment-type {
    padding: rem(2) rem(8);
    border-radius: rem(16);
    color: $color-barbados-blue;
    background: $blue-linear-gradient;
    width: max-content;
  }

  .data-label {
    color: $color-grey-9;
  }
}
