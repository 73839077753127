@import 'styles/functions';
@import 'styles/colors';

.subscription-drawer {
  .calendar-suffix-icon {
    color: $color-regent-grey;
    height: rem(15);
    width: rem(15);
  }

  .timezone-info {
    color: $color-fn-orange;
  }
}
