@import 'styles/colors';
@import 'styles/functions';

@mixin dropdown-styles {
  &.ant-select {
    width: rem(300);
    height: 100%;
    .selected-dropdown-option {
      color: currentColor;
    }
    &-selection-item {
      .selected {
        display: none;
      }
    }
  }
}

.cost-allocation-dashboard-dropdown {
  @include dropdown-styles;
  border-radius: 10px;
  border: 0.5px solid $color-grey-1;

  &.ant-select {
    .ant-select-focused,
    .ant-select-selector,
    .ant-select-selector:focus,
    .ant-select-selector:active,
    .ant-select-open .ant-select-selector {
      border-color: transparent !important;
      box-shadow: none !important;
    }
  }

  .menu-item {
    height: rem(30);
    .logo {
      width: rem(22);
      height: rem(22);
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      &.gcp-icon {
        background-image: url('../../../../assets/icons/gcpLogo.svg');
      }
      &.aws-icon {
        background-image: url('../../../../assets/icons/awsLogo.svg');
      }
      &.azure-icon {
        background-image: url('../../../../assets/icons/azureLogo.svg');
      }
      &.group-icon {
        background-image: url('../../../../assets/icons/groupSmallIcon.svg');
      }
    }
    .dashboard-name {
      display: none;
    }
  }
}

.cost-allocation-dropdown-options {
  &.ant-select-dropdown {
    .ant-select-item-option:hover {
      background-color: $color-grey-0;
      border-radius: rem(10);
    }
    .menu-item {
      color: $color-pure-black;

      .header-logo-container {
        border: 1px solid $color-grey-0;
        border-radius: rem(10);
        background-color: $color-white;
        width: rem(48);
        height: rem(48);
        padding: rem(4);

        .logo {
          width: inherit;
          height: inherit;
          background-size: contain;
          background-position: center;
          background-repeat: no-repeat;
          &.gcp-icon {
            background-image: url('../../../../assets/icons/gcpLogo.svg');
          }
          &.aws-icon {
            background-image: url('../../../../assets/icons/awsLogo.svg');
          }
          &.azure-icon {
            background-image: url('../../../../assets/icons/azureLogo.svg');
          }
          &.group-icon {
            background-image: url('../../../../assets/icons/groupSmallIcon.svg');
          }
        }
      }
    }
  }
}
