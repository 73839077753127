@import 'styles/functions';
@import 'styles/colors';

.reports-table-sort {
  margin: 0 rem(8);

  .sort-heading {
    .add-icon {
      color: $color-regent-grey;
    }
  }
}
