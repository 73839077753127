@import 'styles/functions';
@import 'styles/colors';

.login-page {
  .login-header-section {
    height: rem(82);
    background-color: $color-pure-black;

    .app-logo {
      width: rem(220);
      margin: rem(24) rem(40);
    }

    .deloitte-logo {
      margin: rem(24) rem(40);
    }
  }

  .body-section {
    background-color: $color-bg-light-yellow;
  }

  .section-container {
    border: 1px solid $color-pure-black;
    width: rem(383);
    border-radius: rem(16);

    .content-section {
      padding: rem(24);
      gap: rem(16);
    }
  }
}
