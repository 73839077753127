@import 'styles/functions';
@import 'styles/colors';

.drawer-component {
  .ant-drawer-mask {
    background: rgba(10, 30, 64, 0.8);
    backdrop-filter: blur(2px);
  }

  .ant-drawer-header {
    padding: rem(16) rem(16) rem(24);
    border-bottom: none;
  }

  .ant-drawer-body {
    padding: 0 rem(16) rem(16);
  }

  .ant-drawer-footer {
    background-color: $color-off-white;
    border-color: $color-grey-13;
  }
}
