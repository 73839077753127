@import 'styles/functions';
@import 'styles/colors';
@import 'styles/mixins';

.dashboard-list-item {
  padding: rem(16) 0;
  border-bottom: 1px solid $color-grey-13;

  .ant-radio-wrapper {
    margin: 0;
  }

  .logo {
    width: rem(32);
    height: rem(32);
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    &.gcp-icon {
      background-image: url('../../../../assets/icons/gcpLogo.svg');
    }
    &.aws-icon {
      background-image: url('../../../../assets/icons/awsLogo.svg');
    }
    &.azure-icon {
      background-image: url('../../../../assets/icons/azureLogo.svg');
    }
    &.oci-icon {
      background-image: url('../../../../assets/icons/ociLogo.svg');
    }
    &.group-icon {
      background-image: url('../../../../assets/icons/groupSmallIcon.svg');
    }

    &.disabled-logo {
      filter: grayscale(1);
    }
  }

  .dashboard-status {
    width: rem(8);
    height: rem(8);
    border-radius: rem(10);

    &.published {
      background: $color-primary-green;
    }
    &.draft {
      background: $color-fn-orange;
    }
    &.deactivated {
      background: $color-status-deactivate-2;
    }
  }

  .disabled-option span {
    color: $color-grey-9;
  }

  .datasource-name,
  .weeks-label {
    color: $color-grey-5;
  }

  .dashboard-details {
    @include status-text;

    .dashboard-name-container {
      .group-logos {
        .logo {
          width: rem(16);
          height: rem(16);
        }
      }
    }
  }
}
