@import 'styles/functions';

.ant-carousel {
  .slick-arrow {
    &.slick-next,
    &.slick-prev {
      height: rem(30);
      top: rem(130);
      background: none;
    }
  }
}
