@import 'styles/functions';
@import 'styles/colors';

.budgets-form-page {
  .title-container {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .budget-form {
    height: calc(100vh - 6.8em);

    .progress-bar {
      padding-top: rem(32);
      overflow-y: scroll;
    }

    .form-content {
      overflow-y: scroll;
      padding: rem(24);

      .email-list {
        gap: rem(8);

        .email {
          gap: rem(5);
          height: rem(26);
          background-color: $color-grey-0;
          padding: rem(4) rem(8);
          border-radius: rem(8);
        }
      }
    }
  }
}
