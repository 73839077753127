@import 'styles/functions';
@import 'styles/colors';

.anomaly-detection-dashboard {
  .dashboard-content {
    padding: rem(16);

    .graph-area {
      border-radius: rem(12);
      padding: 1rem;
      background-color: $color-grey-0;
      border: 0.5px solid $color-grey-1;

      &.cost-and-usage,
      &.detection-history,
      &.anomaly-details {
        .graph-header {
          padding: 0;
        }
        .graph {
          padding-top: 1rem;
          height: rem(300);
        }
      }
    }

    .detection-history {
      // TODO: Remove this when the designversion2 is used
      .detection-history-table.fill-container {
        .ant-table-header {
          .ant-table-selection-column {
            background-color: var(--table-header-color);
          }
        }

        height: inherit;
        display: flex;
        flex-direction: column;
        flex: auto;

        .ant-spin-nested-loading {
          height: 100%;
          display: flex;
          flex-direction: column;
          flex: auto;

          .ant-spin-container {
            height: 100%;
            display: flex;
            flex: auto;
            flex-flow: column nowrap;

            .ant-table {
              display: flex;
              flex-direction: column;
              flex: auto;
              height: 100%;
              overflow: hidden;

              .ant-table-container {
                height: 100%;
                display: flex;
                flex: 1;
                flex-flow: column nowrap;

                .ant-table-header {
                  flex: none;
                }

                .ant-table-body {
                  flex: auto;
                  overflow: scroll;
                  display: flex !important;
                  flex-direction: column;
                }
              }
            }

            .ant-table-pagination {
              flex: none;
            }
          }
        }
      }
    }

    .anomaly-details,
    .cost-and-usage {
      .sub-heading {
        opacity: 0.6;
      }
    }

    .anomaly-details {
      height: rem(355);

      .anomaly-data-list {
        padding-top: rem(8);
        height: calc(100% - 2em);
        width: 100%;
        overflow-wrap: anywhere;

        .data {
          overflow: auto;
        }
      }
    }
  }
}

.expand-modal {
  .graph-area {
    padding: rem(14) 0;
    .graph-header {
      padding: 0;
      padding-right: rem(14);
    }
  }

  .anomaly-details,
  .cost-and-usage {
    height: rem(530) !important;

    .graph {
      height: 100%;
    }

    .sub-heading {
      opacity: 0.6;
    }

    .anomaly-data-list {
      padding-top: 1rem;
    }

    .ant-table-wrapper {
      margin-top: rem(16);

      .ant-table-body {
        max-height: calc(100vh - 17em) !important;
      }
    }
  }
}
