@import 'styles/functions';

.insight-table {
  .ant-table-thead {
    .ant-table-column-has-sorters {
      .ant-table-column-sorters {
        align-items: unset;
      }
    }
  }
  .runtime {
    &-box {
      height: rem(15);
      width: rem(15);
      border-radius: rem(4);
    }
  }
}
