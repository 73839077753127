@import 'styles/functions';
@import 'styles/colors';

.tag-item {
  .tag-handle {
    padding-top: rem(2);
    cursor: grab;
    color: $color-regent-grey;
  }

  .remove-icon {
    color: $color-regent-grey;
  }
}
