@import 'styles/functions';
@import 'styles/colors';
@import 'styles/mixins';

.dashboard-list-item {
  padding: rem(16) 0;
  border-bottom: 1px solid $color-grey-13;

  .ant-radio-wrapper {
    margin: 0;
  }

  .description {
    color: $color-grey-5;
  }
}
