@import 'styles/functions';
@import 'styles/colors';

.billing-mapping {
  .billing-mapping-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .table-container {
    height: calc(100vh - 13em);

    .table-section {
      .billing-name {
        .arrow {
          color: $color-regent-grey;
        }
      }

      .provider-icon {
        height: rem(18);
        width: rem(18);
      }
    }
  }

  .pagination {
    margin-top: rem(8);
  }
}
