@import 'styles/colors';

.adduser {
  &-email {
    &-list {
      gap: 7px;
      margin-top: 8px;

      &-email {
        gap: 5px;
        height: 26px;
        background-color: $color-grey-0;
        padding: 4px 8px 6px;
        border-radius: 8px;
      }
    }
  }
  &-button {
    margin-top: 14px;
  }
}
