@import 'styles/colors';
@import 'styles/functions';

.custom-dashboard-header {
  min-height: rem(80);
  padding: 0 rem(24);
  border-bottom: 1px solid $color-grey-10;

  .data-source-type {
    color: $color-grey-5;
    text-transform: capitalize;
  }
}
