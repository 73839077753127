@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.share-dashboard {
  .ant-modal-body {
    .ant-menu {
      &.horizontal-nav-menu {
        overflow: auto;
        overflow-y: hidden;

        .ant-menu-item {
          &.menu {
            color: var(--text-color);
            padding: 0 0 rem(5);
          }

          &.active-menu {
            @extend .font-button;
            color: var(--button-icon-color);
            letter-spacing: 0em;
            border-bottom: rem(3) solid var(--button-icon-color);

            &:hover {
              color: var(--button-icon-color);
              &::after {
                border-bottom: none;
              }
            }
            &::after {
              border-bottom: none;
            }
          }
          &::after {
            border-bottom: none;
          }
        }
      }
    }

    .share-button-cta,
    .update-button-cta {
      width: rem(90);
    }

    .shared-with {
      overflow-y: auto;
      max-height: rem(400);
      padding-right: rem(16);
      .collapse-icon {
        color: $color-grey-4;
      }
    }

    .invite-users,
    .shared-with {
      margin-top: rem(16);
      .profile-icon {
        height: rem(24);
        border-radius: 50%;
      }
    }
  }
}
