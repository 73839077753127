@import 'styles/functions';
@import 'styles/colors';

.gcp-credit-distribution-by-project.graph-card {
  .graph-heading {
    color: $color-grey-5;
  }
}

.dashboard-content {
  .gcp-credit-distribution-by-project.graph-card {
    .graph {
      height: rem(200);
    }
  }
}
