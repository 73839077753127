@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.static-file-import {
  .static-file-import-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .static-file-import-content {
    margin: rem(16) rem(24) rem(24);
    .import-table {
      margin-top: rem(16);

      .ant-table-body {
        max-height: calc(100vh - 20em) !important;

        .overflow-wrap {
          overflow-wrap: anywhere;
        }

        .schema-name-cta {
          text-wrap: wrap;
          height: auto;
        }
      }
    }
  }
}

.info-icon {
  color: $color-fn-red;
}
