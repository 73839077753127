@import 'styles/functions';
@import 'styles/colors';
@import 'styles/mixins';

.commitments-recommendation-wrapper {
  .commitments-recommendation-filter {
    padding: 0 0 rem(8);
  }

  .metric-cards,
  .commitments-recommendations-container {
    border: 1px solid $color-grey-13;
    border-radius: rem(12);
  }

  .commitments-recommendations-container {
    padding: rem(16);

    .create-ticket-cta {
      &.ant-btn {
        &.ant-btn-primary {
          margin-left: rem(8);

          &.error {
            background-color: $color-grey-1 !important;
            color: $color-error-message !important;
          }
        }
      }
    }

    .snow-status-tabs {
      :first-child {
        border-top-left-radius: rem(12);
        border-bottom-left-radius: rem(12);
      }

      :last-child {
        border-top-right-radius: rem(12);
        border-bottom-right-radius: rem(12);
      }
    }

    .recommendations-table {
      .ant-table-thead {
        .ant-table-column-sorters {
          align-items: flex-start;
          .ant-table-column-sorter-up,
          .ant-table-column-sorter-down {
            font-size: rem(10);
          }
        }

        .ant-table-cell-fix-left {
          z-index: 2 !important;
        }
      }

      .ant-table-body {
        .ant-table-tbody {
          .ant-table-cell {
            @include status-text;
          }
        }
      }
    }
  }
}
