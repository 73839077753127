@import 'styles/colors';
@import 'styles/functions';

.integrations-page {
  .title-container {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .table-section {
    margin: rem(16) rem(24) rem(24);

    .search-input {
      margin-bottom: rem(16);
    }
  }

  .page-footer {
    margin: 0 rem(24) rem(24);
  }
}
