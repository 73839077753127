@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/variables';

.tco-dashboard {
  .tco-dashboard-header {
    background-color: $color-white;
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .navigation {
    position: sticky;
    top: rem($page-header-height);
    z-index: 2;
  }
}
