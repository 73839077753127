@import 'styles/functions';
@import 'styles/colors';

.team-row {
  .team-pic {
    height: rem(34);
    width: rem(34);
    border-radius: rem(12);
    background-color: $color-grey-15;
    color: $color-grey-5;
  }

  .email {
    color: $color-grey-5;
  }

  .remove-icon {
    color: $color-regent-grey;
    width: rem(18);
  }

  .team-users-skeleton {
    .ant-skeleton-paragraph {
      margin-bottom: 0;
    }
  }
}
