@import 'styles/functions';
@import 'styles/colors';

.provider-section-body {
  padding: rem(20);
  overflow-y: auto;
  .provider-header {
    text-align: center;
    .provider-heading {
      color: $color-secondary-black;
      font-size: rem(18);
      margin-bottom: rem(8);
    }
    .provider-sub-heading {
      color: $color-grey-9;
    }
  }
  .provider-card-wrapper {
    width: 74%;

    .provider-card {
      border: rem(1) solid $color-grey-13;
      border-radius: rem(12);
      height: rem(100);
      color: $color-secondary-black;
      padding: 0 rem(12);
      margin-top: rem(12);
      gap: rem(18);

      .logo {
        height: rem(43);
        width: rem(43);
      }

      &-about {
        gap: rem(6);
        max-width: rem(360);

        div {
          font-size: rem(20);
          color: $color-secondary-black;
        }
        span {
          color: $color-secondary-black;
        }
      }
      &.selected {
        border: rem(2) solid var(--primary-color);
      }
      &.disabled {
        pointer-events: none;
        .logo {
          filter: grayscale(1);
        }
      }
    }
  }
}
