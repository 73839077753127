@import 'styles/functions';
@import 'styles/colors';

.admin-controls {
  .admin-controls-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
  }

  .controls-container {
    margin: rem(24);

    .controls-list {
      max-height: calc(100vh - 13em);
      overflow: auto;

      .action-cta-icon {
        color: var(--button-icon-color);
      }
    }

    .update-cta {
      margin-left: auto;
    }
  }
}
