@import 'styles/functions';

.view-dropdown {
  width: rem(200);
}

.filter-view-dropdown {
  .dropdown-container {
    max-height: rem(160);
    overflow-y: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }
  }

  .view-type {
    padding: rem(16) rem(8);
  }
  .ruleset-options {
    padding: rem(5) rem(12);
  }
}
