@import 'styles/colors';
@import 'styles/functions';

.connection-form-header {
  &-heading {
    color: $color-secondary-black;
    margin-top: rem(16);
    margin-bottom: rem(8);
  }
  &-sub-heading {
    color: $color-grey-9;
  }
}
