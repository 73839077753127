@import 'styles/functions';
@import 'styles/colors';

.insight-bar {
  height: rem(45);
  background: $color-grey-12;
  padding: 0 rem(16);

  &-item {
    color: $color-secondary-black;
    padding: rem(6) rem(12);
    border-radius: rem(8);
    transition: all 0.3s;

    &:hover {
      background: rgba(0, 0, 0, 0.06);
    }

    &.selected {
      color: $color-primary-button;
      background: $color-white;
      box-shadow: rem(0) rem(2) rem(4) rgba(0, 0, 0, 0.08);
    }
  }
}
