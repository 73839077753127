@import 'styles/functions';
@import 'styles/colors';

.connection-tags-expand-row-table {
  .delete-connection-cta {
    color: $color-regent-grey;
    height: rem(20);
    width: rem(20);
  }
}
