@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/functions';

.cost-comparison-card {
  height: auto;
  margin: rem(12);
  &.vertical-divider {
    padding-left: rem(12);
    border-left: 1px solid $color-grey-13;
  }

  .cost-details {
    .cost {
      line-height: rem(22);
      overflow-wrap: anywhere;
    }
    .cost-type {
      color: $color-grey-5;
    }
  }

  .footer {
    gap: rem(6);
    .comparison {
      gap: rem(4);
      font-weight: 600;
      color: $color-primary-button;

      &.positive {
        color: $color-fn-red;
      }
    }
  }

  .ant-empty {
    margin: 0;

    .ant-empty-image {
      display: none;
    }
  }
}
