@import 'styles/functions';
@import 'styles/colors';

.datasource-options {
  width: rem(280);
  border-left: 1px solid $color-grey-10;

  .datasource-options-navs {
    background: $color-grey-12;
    padding: rem(6);

    .ant-menu {
      flex: 1;
      gap: rem(12);
      &::before,
      &::after {
        display: none;
      }

      .ant-menu-item {
        margin: 0;
        border-radius: rem(8);
        &.menu {
          color: var(--text-color);
        }

        &.active-menu {
          color: var(--button-icon-color);
          background: $color-white;
          box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

          &:hover {
            color: var(--button-icon-color);
          }
        }
      }
    }
  }
}
