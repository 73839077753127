@import 'styles/functions';
@import 'styles/colors';

.logout-overlay {
  .profile-pic {
    height: rem(32);
    width: rem(32);
    border-radius: 50%;
  }

  .email {
    color: $color-grey-5;
  }
}
