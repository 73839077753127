@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.add-edit-csp-connection {
  overflow-y: hidden;
  .csp-page-header {
    height: rem(80);
    padding: 0 rem(24);
    border-bottom: 1px solid rgba(188, 196, 205, 0.4);
    background-color: $color-white;
  }

  .connection-form {
    height: calc(100vh - 5em);

    .new-csp-progress-bar {
      padding-top: rem(32);
      overflow-y: scroll;
    }

    .form-content {
      overflow-y: scroll;
      padding: 8%;

      .csp-form-header > img {
        width: rem(32);
        height: rem(32);
      }

      .cloud-connection-form {
        .ant-radio-button-wrapper {
          background-color: $color-white;
          height: rem(38);
          width: rem(64);
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
