@import 'styles/functions';

.view-list {
  &.card {
    padding: rem(16) rem(8);
  }

  .list-container {
    overflow-y: auto;
    max-height: rem(180);
  }
}
