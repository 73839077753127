@import 'styles/colors';
@import 'styles/functions';

.connection-table-view {
  padding: rem(24);
  overflow: hidden;

  .connection {
    gap: rem(6);
  }
  .date {
    color: $color-secondary-black;
  }

  .status {
    border-radius: rem(16);
    padding: rem(4) rem(8);
    text-transform: capitalize;
    width: fit-content;
  }

  .status-active,
  .status-learning,
  .status-ready-for-activation,
  .status-optimized,
  .status-passive,
  .status-benchmark {
    color: $color-success-message;
    background: $status-active-gradient;
  }

  .status-deactivated,
  .status-failed,
  .status-uninstalled {
    color: $color-status-deactivate;
    background: $status-deactivate-gradient;
  }
  .status-installing,
  .status-initiated,
  .status-loaded,
  .status-uninstalling {
    color: $color-status-installing;
    background: $status-installing-gradient;
  }
}
