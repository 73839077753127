@import 'styles/functions';
@import 'styles/colors';

.custom-table-or-chart-data {
  &:not(.expanded-view) {
    height: rem(318);
    padding: rem(16);
    border: 1px solid $color-grey-13;
    border-radius: rem(12);

    &.selected {
      border: 2px solid var(--primary-color);
    }
  }

  .edit-pencil {
    color: $color-regent-grey;
  }
}

.custom-table-filter-dropdown {
  margin-left: rem(12);
  padding: rem(8) 0;
  max-height: rem(150);
  width: rem(180);
  overflow-y: auto;
  overflow-x: hidden;

  &:has(.range-picker) {
    width: rem(250);
    margin: 0;
    padding: rem(8);
  }
}
