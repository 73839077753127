@import 'styles/functions';

.datasource-form {
  margin: rem(16);
}

.reports-datasource-dropdown {
  .horizontal-navigation-menu-wrapper {
    .ant-menu {
      .ant-menu-item {
        flex: content;
      }
    }
  }
}
