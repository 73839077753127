@import 'styles/functions';
@import 'styles/colors';

.report-table-metrics {
  margin: 0 rem(8);

  .metrics-heading {
    .add-icon {
      color: $color-regent-grey;
    }
  }
}

.metrics-dropdown {
  width: rem(250);
  border-radius: rem(8);
  border: 1px solid $color-grey-13;
  box-shadow: rem(10) rem(14) rem(14) 0 rgba(0, 0, 0, 0.03);

  .report-metrics-group {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: rem(200);

    .ant-checkbox-wrapper {
      overflow-wrap: anywhere;
    }

    .field-panel {
      background-color: transparent;
      border: none;
      margin-bottom: rem(4);

      &.ant-collapse-item-active {
        border: none;
        .ant-collapse-header {
          position: sticky;
          top: rem(-1);
          background-color: $color-white;
          z-index: 1;
        }
      }

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          padding-right: rem(6);

          .collapse-arrow {
            color: $color-grey-9;
          }
        }

        .values-count {
          padding: rem(1) rem(10);
          min-width: rem(22);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }

      .ant-collapse-content {
        background-color: inherit;
        border: none;
        margin: 0 0 0 rem(30);

        .ant-collapse-content-box {
          padding: rem(8) 0 0;
        }
      }
    }
  }
}
