@import 'styles/functions';
@import 'styles/colors';

.connection-list {
  .provider-collapse {
    gap: rem(16);
    .provider-panel {
      background-color: transparent;
      border: none;

      &.ant-collapse-item-active {
        border: none;
      }

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          padding-right: rem(6);

          .provider-collapse-arrow {
            color: $color-grey-9;
          }
        }

        .values-count {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }

      .ant-collapse-content {
        background-color: inherit;
        border: none;
        margin: 0 0 0 rem(26);

        .ant-collapse-content-box {
          padding: rem(8) 0 0;
        }
      }
    }
  }
  .connection-list {
    overflow-y: auto;
    max-height: rem(400);

    .provider-logo {
      width: rem(24);
      height: rem(24);
      background-color: $color-white;
      border-radius: 50%;
      text-align: center;
    }

    .migrated-label {
      opacity: 0.7;
    }
  }
}
