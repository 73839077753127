@import 'styles/functions';

.tag-filters {
  margin-bottom: rem(12);
  padding-bottom: rem(12);
  overflow-x: auto;

  .tag-title {
    margin: auto 0;
  }
}
