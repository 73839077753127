@import 'styles/colors';
@import 'styles/functions';

.conjunctions-overlay {
  .conjunction-option {
    padding: rem(4);
    border-radius: rem(4);
    &:hover {
      background-color: var(--button-icon-color);
      color: $color-white;
    }
  }
}
