@import 'styles/colors';
@import 'styles/functions';
@import 'styles/fonts';

.tco-dashboard-list-modal {
  .ant-drawer-content-wrapper {
    width: rem(600) !important;

    .search-input {
      width: rem(250);
    }

    .horizontal-navigation-menu-wrapper {
      border-radius: rem(12);
      border: 1px solid rgba(188, 196, 205, 0.4);
      background: $color-grey-12;
      padding: rem(6);

      .ant-menu {
        flex: 1;
        gap: rem(12);
        &::before,
        &::after {
          display: none;
        }

        .ant-menu-item {
          margin: 0;
          border-radius: rem(8);
          &.menu {
            color: var(--text-color);
          }

          &.active-menu {
            color: var(--button-icon-color);
            background: $color-white;
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.08);

            &:hover {
              color: var(--button-icon-color);
            }
          }
        }
      }
    }
  }
}
