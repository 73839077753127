@import 'styles/colors';
@import 'styles/functions';

.time-picker-wrapper {
  .suffix-icon {
    color: $color-regent-grey;
    height: rem(15);
    width: rem(15);
  }
}
