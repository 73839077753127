@import 'styles/colors';
@import 'styles/functions';

.horizontal-navigation-menu-wrapper {
  background-color: $color-grey-12;
  padding: rem(6) rem(24);
  border-bottom: $color-grey-10 1px solid;

  .nav-menu {
    background-color: inherit;
    overflow: auto;

    &.ant-menu-vertical {
      border-inline-end: none;
    }

    &.ant-menu-vertical:has(.ant-carousel) {
      margin-right: 1rem;
    }

    .ant-menu-item {
      height: rem(28);
      display: flex !important;
      align-items: center;
      justify-content: center;
      margin: 0;
      margin-right: rem(16);
      padding: 0 rem(12);
      width: auto;

      &.ant-menu-item-selected {
        background-color: $color-white;
        color: var(--button-icon-color);
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);
      }
    }

    .nav-menu-carousel {
      .slick-arrow {
        top: 50%;
        width: rem(20);
        height: rem(20);
        padding: 0;
        border-radius: 50%;
        background-color: $color-white;
        color: $color-primary-button;
        box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.08);

        :hover {
          color: $color-primary-button;
        }

        &.slick-prev {
          left: 0;
        }

        &.slick-next {
          right: 0;
        }

        &.slick-disabled {
          display: none !important;
        }
      }

      .slick-slide {
        margin-right: rem(12);

        > div {
          height: rem(28);
        }
      }
    }
  }
}
