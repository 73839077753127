@import 'styles/functions';
@import 'styles/colors';

.required-tags-drawer {
  .selected-tags-count {
    padding: rem(1) rem(10);
    min-width: rem(26);
    height: rem(18);
    background: $color-grey-12;
    color: $color-grey-5;
    border-radius: rem(48);
  }

  .selected-tag {
    background-color: $color-grey-12;
    padding: rem(8);
    border-radius: rem(12);

    .remove-icon {
      color: $color-regent-grey;
      width: rem(18);
    }
  }
}

.ant-select-dropdown {
  .tags-dropdown {
    height: rem(250);
    padding: rem(8);
    overflow: auto;

    .tag-key:hover {
      color: var(--primary-color);
    }

    .tag-key {
      &.disabled {
        .key-label {
          color: $color-grey-5;
        }
      }
    }
  }
}
