@import 'styles/colors';
@import 'styles/fonts';
@import 'styles/functions';

.graph-header {
  padding: 1rem 1rem 0 1rem;
  &.ignore-padding-bottom {
    padding-bottom: 0;
  }

  .expand-icon {
    gap: 0.5rem;
  }
  .comparison {
    gap: 0.25rem;
    @extend .font-button;
    color: $color-primary-button;

    &.positive {
      color: $color-fn-red;
    }
  }
  .comparison-below {
    .comparison {
      @extend .font-comparison-label;
      font-weight: 600;
    }
  }
  .download-icon {
    margin-right: rem(12);
  }
  .table-view-title {
    margin-right: rem(12);
    color: $color-grey-9;
  }
}

.graph-header-v2 {
  &.ignore-padding-bottom {
    padding-bottom: 0;
  }

  .expand-icon {
    gap: 0.5rem;

    .additional-components {
      margin-right: rem(12);
    }
  }
  .comparison {
    gap: 0.25rem;
    @extend .font-button;
    color: $color-primary-button;

    &.positive {
      color: $color-fn-red;
    }
  }
  .comparison-below {
    .comparison {
      @extend .font-comparison-label;
      font-weight: 600;
    }
  }
  .download-icon {
    margin-right: rem(12);
  }
  .table-view-title {
    margin-right: rem(12);
    color: $color-grey-9;
    text-wrap: nowrap;
  }
}
