@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.groups-page {
  .title-container {
    height: rem($page-header-height);
    padding: 0 rem(24);
    border-bottom: 1px solid $color-grey-10;
  }

  .table-section {
    margin: rem(16) rem(24) rem(24);

    .search-input {
      margin-bottom: rem(16);
    }
  }

  .page-footer {
    margin: 0 rem(24) rem(24);
  }
}

.group-connection-names {
  background-color: $color-grey-1;
  border-radius: rem(8);
  padding: rem(4) rem(8) rem(6);
  margin: rem(2);
}
