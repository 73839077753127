@import 'styles/colors';
@import 'styles/functions';

.all-features-sub-menu {
  padding: 0 rem(16) rem(16);

  .menu-title {
    color: $color-grey-9;
    text-transform: uppercase;
  }

  .sub-menu-items {
    &.all-features-sub-menu {
      margin: 0 0 rem(8) 0;
      padding: 0;
    }
  }
}
