@import 'styles/functions';
@import 'styles/colors';

.profile-bar {
  height: rem(40);
  background: $color-grey-12;
  margin: rem(10) 0;
  padding: 0 rem(10);
  border-radius: rem(8);

  .profile-bar-item {
    border-right: 1px solid $color-grey-13;
    flex-flow: wrap;
    flex: auto;
    padding: rem(4) 0;

    &:last-child {
      border-right: none;
    }

    .positive {
      color: $color-primary-button;
    }

    .negative {
      color: $color-fn-red;
    }

    .label {
      width: fit-content;
    }

    .value {
      margin-left: rem(4);
    }

    .active-loader {
      width: auto;
    }
  }
}

.consumption-overlay {
  margin: rem(8);
  width: rem(200);
  height: rem(200);

  .heading {
    margin-bottom: rem(8);
  }
}
