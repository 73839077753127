@import 'styles/functions';
@import 'styles/colors';

.gProfiler-insight-dashboard {
  margin: rem(16) rem(24) 0;

  .profile-bar {
    height: rem(40);
    background: $color-grey-12;
    margin: rem(10) 0;
    padding: 0 rem(10);
    border-radius: rem(8);
  }

  .insight-dashboard-graph {
    .graph-header-item {
      height: rem(40);
      background: $color-grey-12;
      padding: 0 rem(10);
      border-radius: rem(11) rem(11) 0 0;
      align-items: center;
    }
  }

  .insights-graph {
    height: calc(100vh - 21em);
  }

  .language-legends {
    background: $color-grey-12;
    border-radius: 0 0 rem(11) rem(11);
    padding: rem(10);

    .legend {
      border-radius: rem(4);
      height: rem(16);
      width: rem(16);
    }
  }
}

.gprofiler-insights-date-range-panel {
  .date-range-options {
    padding: rem(16);
  }
}

.expand-modal {
  .ant-modal-body {
    .insight-dashboard-graph {
      .graph-header-item {
        height: rem(40);
        background: $color-grey-12;
        padding: 0 rem(10);
        border-radius: rem(11) rem(11) 0 0;
        align-items: center;
      }
    }

    .insights-graph {
      height: calc(100% - 5em);
    }

    .language-legends {
      background: $color-grey-12;
      border-radius: 0 0 rem(11) rem(11);
      padding: rem(10);

      .legend {
        border-radius: rem(4);
        height: rem(16);
        width: rem(16);
      }
    }
  }
}
