@import 'styles/functions';
@import 'styles/colors';

.ant-table-filter-dropdown {
  border-radius: rem(12) !important;

  .table-filter-dropdown {
    padding: rem(16);

    .filter-key {
      margin-left: rem(24);
    }
  }
}

.table-filter-dropdown {
  &.design-version-2 {
    padding: 0;
    margin-left: rem(0);
    width: rem(222);
    border: 1px solid $color-grey-13;
    border-radius: rem(12);

    .filters {
      padding: rem(16);
      .filter-key {
        margin-left: 0;
      }
    }

    .footer-buttons {
      padding: rem(4);
      background: $color-off-white;
      border-top: 1px solid $color-grey-13;
      border-radius: 0 0 rem(12) rem(12);
    }
  }
}
