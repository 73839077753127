@import 'styles/functions';
@import 'styles/colors';

.add-or-edit-teams-drawer {
  .search-placeholder {
    color: $color-grey-2;
  }

  .team-users-list {
    .selected-users {
      .arrow-down {
        color: $color-regent-grey;
      }

      .users-count {
        .users-count-value {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }
    }

    .team-users-list {
      max-height: calc(100vh - 22em);
      overflow: auto;
    }
  }
}
