@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.tags-labels-header {
  background-color: $color-white;
  border-bottom: $color-grey-10 1px solid;
  padding: rem(16) rem(24);
  min-height: rem($page-header-height);

  .back-arrow {
    color: var(--button-icon-color);
  }

  .header-title {
    gap: 1rem;

    .title-container {
      gap: rem(6);
    }

    .data-source-type {
      opacity: 0.7;
      text-transform: capitalize;
    }

    .sub-title-container {
      gap: rem(4);
    }
  }
}
