@import 'styles/functions';

.cost-optimization-insight-graph-header {
  padding: rem(16);
  word-break: break-word;

  &.ignore-padding {
    padding: 0;
  }

  .download-icon {
    margin-right: rem(12);
  }
  .table-view-title {
    margin-right: rem(12);
  }
}
