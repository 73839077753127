.flex {
  display: flex;

  &-center {
    align-items: center;
    justify-content: center;
  }

  &-align-items-center {
    align-items: center;
  }

  &-align-items-baseline {
    align-items: baseline;
  }

  &-justify-content-center {
    justify-content: center;
  }

  &-justify-content-left {
    justify-content: left;
  }

  &-space-between {
    justify-content: space-between;
  }

  &-space-around {
    justify-content: space-around;
  }

  &-space-evenly {
    justify-content: space-evenly;
  }

  &-end {
    justify-content: flex-end;
  }

  &-start {
    justify-content: flex-start;
  }

  &-align-items-start {
    align-items: flex-start;
  }

  &-align-items-end {
    align-items: flex-end;
  }

  &-align-self-start {
    align-self: flex-start;
  }

  &-align-self-end {
    align-self: flex-end;
  }

  &-align-self-center {
    align-self: center;
  }

  &-place-items-start {
    place-items: start;
  }

  &-row {
    flex-direction: row;
  }

  &-column {
    flex-direction: column;
  }

  &-wrap {
    flex-wrap: wrap;
  }

  &-no-wrap {
    flex-wrap: nowrap;
  }

  &-fit {
    flex: 1;
  }

  &-half-fit {
    flex: 0.5;
  }

  &-inline {
    display: inline-flex;
  }

  &-gap-2 {
    gap: rem(2);
  }

  &-gap-4 {
    gap: rem(4);
  }

  &-gap-8 {
    gap: rem(8);
  }

  &-gap-10 {
    gap: rem(10);
  }

  &-gap-12 {
    gap: rem(12);
  }

  &-gap-16 {
    gap: rem(16);
  }

  &-gap-24 {
    gap: rem(24);
  }
}
