@import 'styles/functions';

.gprofiler-connection-form {
  overflow-y: auto;
  padding: rem(16) rem(8);
}

@media (max-height: rem(850)) {
  .gprofiler-connection-form {
    max-height: rem(572);
  }
}

@media (max-height: rem(722)) {
  .gprofiler-connection-form {
    max-height: rem(540);
  }
}
