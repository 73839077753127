@import 'styles/colors';
@import 'styles/functions';
@import 'styles/variables';

.rule-engine-list {
  .rule-engine-list-header {
    border-bottom: $color-grey-10 1px solid;
    padding: rem(16) rem(24);
    height: rem($page-header-height);
  }

  .rule-engine-list-content {
    margin: rem(16) rem(24) rem(24);

    .table-section {
      margin-top: rem(16);
    }
  }
}
