@import 'styles/functions';
@import 'styles/colors';

.connection-tag-dropdown {
  padding: rem(12);

  .connection-tag-collapse {
    max-height: rem(300);
    min-height: rem(200);
    margin-top: rem(16);
    gap: rem(16);
    overflow: auto;

    .connection-tag-panel {
      background-color: transparent;
      border: none;

      &.ant-collapse-item-active {
        border: none;
      }

      .ant-collapse-header {
        padding: 0;

        .ant-collapse-expand-icon {
          padding-right: rem(6);

          .collapse-arrow {
            color: $color-grey-9;
          }
        }

        .values-count {
          padding: rem(1) rem(10);
          min-width: rem(26);
          height: rem(18);
          background: $color-grey-12;
          border-radius: rem(48);
        }
      }

      .ant-collapse-content {
        background-color: inherit;
        border: none;
        margin: 0 0 0 rem(30);

        .ant-collapse-content-box {
          padding: rem(8) 0 0;
        }
      }
    }
  }
}
