@import 'styles/functions';
@import 'styles/colors';
@import 'styles/fonts';

.edit-modal-container {
  gap: rem(16);
  .header {
    padding: rem(16);
    gap: rem(7);
    background-color: $color-grey-0;
    border-radius: rem(12);
    .icon {
      height: rem(42);
      width: rem(42);
      border-radius: 50%;
    }
    .email {
      color: var(--button-icon-color);
    }
    .role {
      margin-left: auto;
      background-color: $color-fn-orange;
      color: $color-white;
      border-radius: rem(8);
      padding: rem(4) rem(8);
      height: fit-content;
    }
  }
  .change-role {
    gap: rem(8);
  }
  .ant-divider-horizontal {
    margin: 0;
  }
  .pending-message {
    color: $color-fn-orange;
  }
  .cloud-connection-edit {
    .cloud-input-section {
      gap: rem(8);
      .form-label {
        margin: 0;
      }
      .ant-select-selector {
        .ant-select-selection-item {
          display: none;
        }
      }
    }
  }
}

.connections-groups-options {
  .connections-groups-switch {
    height: rem(35);
    padding: 0 rem(10);

    .switch-items {
      border-bottom: rem(1) solid $color-grey-3;
      color: $color-grey-5;
    }
    .active-switch {
      border-bottom: rem(1) solid var(--button-icon-color);
      color: var(--button-icon-color);
    }
  }

  .dropdown-checkbox {
    margin-right: rem(4);
  }
}
