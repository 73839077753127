@import 'styles/colors';
@import 'styles/functions';

.addrole {
  &-button {
    margin-top: 14px;
  }

  .index {
    width: 10%;
    text-align: center;
  }

  .email {
    width: 60%;
    text-align: left;
  }

  .role {
    width: 25%;
    text-align: left;
    padding-right: 1rem !important;

    &-column {
      padding-left: rem(5);
      gap: 4px;
    }
  }

  .ant-table {
    table {
      .ant-table-tbody {
        tr {
          & td:last-child {
            text-align: left;
          }
        }
      }
    }
  }

  .roles-menu {
    &.ant-select {
      .ant-select-selector {
        border: none;
        background-color: transparent !important;
        width: rem(150);
        &:active {
          background-color: transparent;
        }
      }
      .ant-select-arrow {
        color: $color-secondary-black;
      }

      .ant-select-focused,
      .ant-select-selector,
      .ant-select-selector:focus,
      .ant-select-selector:active,
      .ant-select-open .ant-select-selector {
        border-color: transparent !important;
        box-shadow: none !important;
      }
    }
  }
}

.ant-dropdown-trigger {
  &.ant-dropdown {
    position: inherit;
    top: 0;
    left: 0;

    ul {
      list-style: none;
      background-color: $color-white;
      border-radius: rem(10);
      padding: rem(16);
      margin: 0 20% 0 60%;
      width: fit-content;
      li {
        padding: rem(4);
      }
    }
  }
}
